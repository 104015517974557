import * as React from "react";
import { useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ICategoryNamesByCollection } from "../../selectors/categories";
import { ICollection } from "../../types/ICollection";
import { IMedia } from "../../types/IMedia";
import { Colors } from "../../utils/Colors";
import styled from "styled-components";
import { VisibilityFilterContainer } from "../shared/VisibilityFilterContainer";
import { CollectionsListGallery } from "./CollectionsListGallery";

interface IProps extends RouteComponentProps {
  allCategoryByCollection: ICategoryNamesByCollection;
  collections: ICollection[];
  media: IMedia[];
  updateAppBgColor: (color: string) => void;
}

export function CollectionsList({
  updateAppBgColor,
  allCategoryByCollection,
  collections,
  media
}: IProps) {
  useEffect(() => {
    updateAppBgColor(Colors.WHITE);
  }, [updateAppBgColor]);

  if (collections.length === 0) {
    return (
      <View>
        <View color={Colors.WHITE}>
          <div>
            <h3>There are no collections yet.</h3>
            <Link to="/">Return to home</Link>
          </div>
        </View>
      </View>
    );
  } else {
    return (
      <View>
        <div>
          <VisibilityFilterContainer />
          <CollectionsListGallery
            allCategoryByCollection={allCategoryByCollection}
            collections={collections}
            media={media}
          />
        </div>
      </View>
    );
  }
}

const View = styled.div`
  padding: 5rem 0 0;
  width: 100%;
  text-align: center;

  > div > a {
    display: block;
    font-size: 1.1rem;
    margin: 2rem 0;
  }

  @media (min-width: 425px) {
    padding: 10.2rem 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;
