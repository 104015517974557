import * as React from "react";
import { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IMedia } from "../../types/IMedia";
import { ITextile } from "../../types/ITextile";
import { Colors } from "../../utils/Colors";
import { TextilesGallery } from "./TextilesGallery";
import { PageTitle } from "../shared/PageTitle";

interface IParams {}

interface IProps extends RouteComponentProps<IParams> {
  textiles: ITextile[];
  media: IMedia[];
  updateAppBgColor: (color: string) => void;
}

export function Textiles({ updateAppBgColor, textiles, media }: IProps) {
  useMemo(() => {
    updateAppBgColor(Colors.WHITE);
  }, [updateAppBgColor]);

  return (
    <>
      <TextilesGallery textiles={textiles} media={media} />
      <PageTitle title="Textiles" />
    </>
  );
}
