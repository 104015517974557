import axios from "axios";
import { ITextile } from "../types/ITextile";

export const FETCH_TEXTILES_BEGIN = "FETCH_TEXTILES_BEGIN";
export const FETCH_TEXTILES_SUCCESS = "FETCH_TEXTILES_SUCCESS";
export const FETCH_TEXTILES_FAILURE = "FETCH_TEXTILES_FAILURE";

export const fetchTextilesBegin = () => ({
  type: FETCH_TEXTILES_BEGIN
});

export const fetchTextilesSuccess = (textiles: ITextile[]) => ({
  payload: { textiles },
  type: FETCH_TEXTILES_SUCCESS
});

export const fetchTextilesFailure = (error: any) => ({
  payload: { error },
  type: FETCH_TEXTILES_FAILURE
});

export function fetchTextiles() {
  return function action(dispatch: any) {
    dispatch(fetchTextilesBegin());

    const request = () =>
      axios({
        headers: [],
        method: "GET",
        url: `/wp-json/wp/v2/textile`,
        params: {
          _fields: "id,image,description,downloadtext,collection",
          status: "publish",
          per_page: 100
        }
      });

    request().then(
      ({ data }: { data: ITextile[] }) => {
        dispatch(fetchTextilesSuccess(data));
      },
      err => dispatch(fetchTextilesFailure(err))
    );
  };
}
