import * as React from "react";
import styled from "styled-components";
import logo from "./logo.svg";

export function Logo() {
  const LogoView = styled.img`
    width: 65vw;
    height: min-intrinsic;

    @media (min-width: 425px) {
      width: 15rem;
    }

    @media (min-width: 541px) {
      width: 19rem;
    }
  `;

  return <LogoView src={logo} />;
}
