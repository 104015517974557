import styled from "styled-components";
import arrow from "./arrow_downward-24px.svg";

const ScrollArrow = styled.i`
  background: url(${arrow});
  background-repeat: no-repeat;
  width: 16px;
  height: 24px;
  display: block;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
`;

export { ScrollArrow };
