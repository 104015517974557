import * as React from "react";
import styled, { keyframes } from "styled-components";

const View = ({ className }: { className?: string }) => (
  <div className={className}>
    <i />
  </div>
);

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;

const Loading = styled(View)`
  pointer-events: none;
  position: fixed;
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.1rem;
  text-transform: uppercase;

  i {
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  i:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1) transparent rgba(0, 0, 0, 0.1) transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

export { Loading };
