import { compose, map, range, uniq } from "ramda";
import * as React from "react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { IMedia } from "../../types/IMedia";
import { ILookbook } from "../../types/ILookbook";
import { VisibilityFilterContainer } from "../shared/VisibilityFilterContainer";
import { LookbookGalleryView } from "./LookbookGallery";

interface IProps {
  children?: JSX.Element[];
  media: IMedia[];
  lookbook: ILookbook[];
  fetchLookbook: () => void;
  fetchMedia: (listIds: string[], cache: IMedia[]) => void;
}

export function LookbookLoader({
  children,
  media,
  lookbook,
  fetchLookbook,
  fetchMedia
}: IProps) {
  const [isLoading, setIsLoading] = useState(true);

  useMemo(() => {
    fetchLookbook();
  }, [fetchLookbook]);

  useMemo(() => {
    if (lookbook.length > 0) {
      const uniqueMediaIds = compose<ILookbook[], string[], string[]>(
        uniq,
        map((x: ILookbook) => x.image.ID)
      );

      fetchMedia(uniqueMediaIds(lookbook), media);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookbook, fetchMedia]);

  return (
    <View>
      <div>
        <VisibilityFilterContainer />
        <LookbookGalleryView>
          {isLoading ? (
            <ul>
              {range(0, 9).map((x, i) => (
                <li key={i} />
              ))}
            </ul>
          ) : (
            <>{children}</>
          )}
        </LookbookGalleryView>
      </div>
    </View>
  );
}

export const NextPage = styled.div`
  font-size: 1.1rem;
  margin: 1rem 0 5rem;
  &:hover {
    font-weight: bold;
  }
`;

export const View = styled.div`
  padding: 5rem 0 0;
  width: 100%;
  text-align: center;

  > div > a {
    display: block;
    font-size: 1.1rem;
    margin: 2rem 0;
  }

  @media (min-width: 425px) {
    padding: 10.2rem 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > div {
      width: 58rem;
    }
  }
`;
