import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchMedia } from "../../actions/media";
import { IStore } from "../../reducers";
import { CollectionsLoader } from "./CollectionsLoader";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  collections: state.collections.items
});

const mapDispatchToProps = {
  fetchMedia
};

const CollectionsLoaderContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionsLoader) as any);

export { CollectionsLoaderContainer };
