import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { IStore } from "../../reducers";
import { Collections } from "./Collections";

const mapStateToProps = (state: IStore, ownProps: any) => ({
  collections: state.collections.items
});

const mapDispatchToProps = {
  updateAppBgColor
};

const CollectionsContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Collections)
);

export { CollectionsContainer };
