import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchMedia } from "../../actions/media";
import { fetchProducts } from "../../actions/products";
import { IStore } from "../../reducers";
import { ProductsLoader } from "./ProductsLoader";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  products: state.products.items
});

const mapDispatchToProps = {
  fetchProducts,
  fetchMedia
};

const ProductsLoaderContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsLoader) as any);

export { ProductsLoaderContainer };
