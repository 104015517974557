import { connect } from "react-redux";
import { withRouter } from "react-router";
import { IStore } from "../../reducers";
import { Home } from "./Home";

const mapStateToProps = (state: IStore) => ({
  currentCollection: state.home.currentCollection
});

const mapDispatchToProps = {};

const HomeContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);

export { HomeContainer };
