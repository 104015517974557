//#region
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ICollection } from "../../types/ICollection";
import { ILookbook } from "../../types/ILookbook";
import { isMobile } from "../../utils";
import { Colors } from "../../utils/Colors";
import { BackgroundImage } from "../shared/BackgroundImage";

interface IProps {
  lookbook: ILookbook;
  collection: ICollection;
  mediaUrl: string;
}
//#endregion

export function LookbookGalleryItem({
  collection,
  mediaUrl,
  lookbook
}: IProps) {
  const [preview, setPreview] = useState(false);
  const toggle = (value: boolean) => () => setPreview(value);

  return isMobile() ? (
    <LookbookGalleryItemView preview={false}>
      <a rel="noopener noreferrer" target="_blank" href={lookbook.link}>
        <BackgroundImage src={mediaUrl} />
      </a>
    </LookbookGalleryItemView>
  ) : (
    <LookbookGalleryItemView
      onMouseOver={toggle(true)}
      onMouseOut={toggle(false)}
      preview={preview}
    >
      <article>
        <h2>
          <Link to={`/collection/${collection.slug}/`}>
            {collection.title.rendered}
          </Link>
        </h2>
        <h3>{lookbook.description}</h3>
        <p>
          <a rel="noopener noreferrer" target="_blank" href={lookbook.link}>
            Download the catalogue here
          </a>
          {lookbook.downloadtext}
        </p>
      </article>
      <BackgroundImage src={mediaUrl} />
    </LookbookGalleryItemView>
  );
}

// #region views
const LookbookGalleryItemView = styled.li<{ preview: boolean }>`
  padding-top: 100%;
  position: relative;
  margin: 1em 1em 0;
  transition: background-color 1s 200ms;
  background-color: transparent !important;

  ${BackgroundImage} {
    position: absolute;
    z-index: 1;
    top: 0;
    padding-top: 100%;
  }

  &:last-child {
    margin: 1em 1em;
  }

  a:hover {
    font-weight: bold;
  }

  > article {
    display: ${({ preview }) => (preview ? "flex" : "none")};
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1.2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.WHITE};
    text-transform: uppercase;

    > h2 {
      font-size: 1.1rem;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0.5em;
      width: 60%;
    }

    > h3 {
      font-size: 0.9rem;
      width: 60%;
    }

    > p {
      width: 60%;
      margin-top: 1em;
      font-size: 0.8rem;
      text-align: center;
      line-height: 1.6em;
      a {
        font-size: 0.9rem;
        display: block;
      }
    }

    > ul {
      margin: 0.8rem 0 2.3rem 0;
      font-size: 0.9rem;
      li {
        text-transform: uppercase;
        line-height: 1.3rem;
      }
    }

    > a {
      text-transform: uppercase;
    }

    > a:hover {
      font-weight: bold;
    }
  }

  img {
    width: 100%;
  }

  @media (min-width: 425px) {
    width: 100%;
    margin: 0 auto;

    &:last-child {
      margin: 0 auto;
    }
  }
`;
// #endregion
