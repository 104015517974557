import R_append from "ramda/es/append";
import R_compose from "ramda/es/compose";
import R_curry from "ramda/es/curry";
import R_join from "ramda/es/join";
import R_take from "ramda/es/take";
import R_when from "ramda/es/when";
import { css } from "styled-components";
import "url-polyfill";

export function randMax(max: number): number {
  return Math.random() * max;
}

export const ie11 = (style: any) => {
  return css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ${style};
    }
  `;
};

export const truncate = R_curry((limit: number, x: string) =>
  R_when<string, string>(
    str => str.length > limit,
    R_compose(R_join(""), R_append("…"), R_take(limit))
  )(x)
);

export const isMobile = () => window.innerWidth < 425;
