import * as R from "ramda";
import { IMedia } from "../types/IMedia";

export function fixUrl(x: string) {
  try {
    return x.replace(new URL(x).origin, "");
  } catch (e) {
    return "";
  }
}

export const ratio = (x: IMedia) =>
  x.media_details.height / x.media_details.width;

export const imageUrl = (
  x: IMedia,
  size:
    | "thumbnail"
    | "medium"
    | "medium_large"
    | "large"
    | "full" = "medium_large"
) =>
  fixUrl(
    R.pathOr<string, string>(
      "",
      ["media_details", "sizes", size, "source_url"],
      x
    )
  );

export const sizeFromRatio = (width: number, r: number) => ({
  width: `${width}px`,
  height: `${300 * r}px`
});

const toThumb = (guid: string) => ({
  width: 1,
  height: 1.5,
  source_url: `${guid}`
});

export const toMediaDefault = (guid: string) => ({
  id: -1,
  guid: {
    rendered: guid
  },
  source_url: guid,
  media_details: {
    width: 1,
    height: 1.5, // let's assume that the image is portrait
    sizes: {
      thumbnail: toThumb(guid),
      medium: toThumb(guid),
      medium_large: toThumb(guid),
      large: toThumb(guid),
      full: toThumb(guid)
    }
  }
});

export const findMediaByGUID = (guid: string, media: IMedia[]): IMedia => {
  // try to find the image in the list
  // loaded when the application starts
  const find = R.find((m: IMedia) => m.id.toString() === guid);

  // fallback to the same guid, so it will load
  // the biggest one since we don't have info about
  // this image
  const fallback: IMedia = toMediaDefault(guid);

  return R.compose<IMedia[], IMedia | undefined, IMedia>(
    R.defaultTo(fallback),
    find
  )(media);
};
