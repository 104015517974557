import axios from "axios";
import * as R from "ramda";
import { IMedia } from "../types/IMedia";

interface IWithMedia {
  data: IMedia[];
}

export const FETCH_MEDIA_BEGIN = "FETCH_MEDIA_BEGIN";
export const FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const FETCH_MEDIA_FAILURE = "FETCH_MEDIA_FAILURE";

export const fetchMediaBegin = () => ({
  type: FETCH_MEDIA_BEGIN
});

export const fetchMediaSuccess = (media: IMedia[]) => ({
  payload: { media },
  type: FETCH_MEDIA_SUCCESS
});

export const fetchMediaFailure = (error: any) => ({
  payload: { error },
  type: FETCH_MEDIA_FAILURE
});

const createRequest = (ids: string[]) =>
  axios({
    headers: [],
    method: "GET",
    url: `/wp-json/wp/v2/media/`,
    params: {
      media_type: "image",
      per_page: 100,
      include: ids.join(",")
    }
  });

const rejectInCache = (cache: IMedia[], ids: string[]) =>
  R.reject(x => cache.find(c => c.id.toString() === x) !== undefined, ids);

export function fetchMedia(listIds: string[], cache: IMedia[]) {
  return function action(dispatch: any) {
    dispatch(fetchMediaBegin());

    // let's reject those ids from media that are already in the store
    const nonCachedIds = rejectInCache(cache, listIds);

    if (nonCachedIds.length === 0) {
      // all media is loaded in cache we don't have any new id
      return Promise.resolve(() => {
        dispatch(fetchMediaSuccess(cache));
      });
    }

    // let's calculate how many pages do we need to load, since
    // we have a limit of 100 elements by request we have to splite them
    const numPages = Math.ceil(nonCachedIds.length / 100);

    // let's create a batch 100 ids for each request
    const requests = R.range(0, numPages).map((_, idx) =>
      createRequest(R.slice(100 * idx, 100 * (idx + 1), nonCachedIds))
    );

    return axios.all(requests).then(res => {
      const groupData = (acc: IMedia[], obj: IWithMedia) =>
        acc.concat(obj.data);

      // let's merge all the results in a single array
      const merged = R.reduce<IWithMedia, IMedia[]>(groupData, [], res);
      // and put them in the store
      dispatch(fetchMediaSuccess(merged));
    });
  };
}

// Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
