import axios from "axios";
import { ILookbook } from "../types/ILookbook";

export const FETCH_LOOKBOOK_BEGIN = "FETCH_LOOKBOOK_BEGIN";
export const FETCH_LOOKBOOK_SUCCESS = "FETCH_LOOKBOOK_SUCCESS";
export const FETCH_LOOKBOOK_FAILURE = "FETCH_LOOKBOOK_FAILURE";

export const fetchLookbookBegin = () => ({
  type: FETCH_LOOKBOOK_BEGIN
});

export const fetchLookbookSuccess = (lookbook: ILookbook[]) => ({
  payload: { lookbook },
  type: FETCH_LOOKBOOK_SUCCESS
});

export const fetchLookbookFailure = (error: any) => ({
  payload: { error },
  type: FETCH_LOOKBOOK_FAILURE
});

export function fetchLookbook() {
  return function action(dispatch: any) {
    dispatch(fetchLookbookBegin());

    const request = () =>
      axios({
        headers: [],
        method: "GET",
        url: `/wp-json/wp/v2/lookbook`,
        params: {
          _fields: "id,collection,link,image,description,downloadtext",
          status: "publish",
          per_page: 100
        }
      });

    request().then(
      ({ data }: { data: ILookbook[] }) => {
        dispatch(fetchLookbookSuccess(data));
      },
      err => dispatch(fetchLookbookFailure(err))
    );
  };
}
