import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ie11 } from "../../utils";
import UrlUtils from "../../utils/UrlUtils";
import styled, { css } from "styled-components";
import { ICollection } from "../../types/ICollection";
import MetaShare from "../MetaShare";

interface IProps extends RouteComponentProps {
  collection: ICollection;
}

class CollectionNameWrapper extends React.PureComponent<IProps> {
  public render() {
    const collection = this.props.collection;
    const theTitle = collection.title.rendered;
    const theImage =
      UrlUtils.origin(document) + collection.collection_featured_image.guid;
    const isEnabled = this.props.match.url !== `/collection/${collection.slug}`;
    const clickHandler = isEnabled ? this.clickHandler : undefined;

    return (
      <CollectionNameView isEnabled={isEnabled} onClick={clickHandler}>
        <MetaShare title={`Lola Valentina - ${theTitle}`} image={theImage} />
        {theTitle}
      </CollectionNameView>
    );
  }

  private clickHandler = () => {
    const collectionSlug = this.props.collection.slug;
    this.props.history.push(`/collection/${collectionSlug}/`);
  };
}

const CollectionNameView = styled.h1<{ isEnabled: boolean }>`
  z-index: 1;
  flex: 0 0 100%;
  display: block;
  margin: 0 0 3.4rem;
  padding: 0;
  width: 100%;

  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.1rem;

  &:hover {
    font-weight: ${({ isEnabled }) => (isEnabled ? "bold" : "normal")};
  }

  ${ie11(css`
    position: absolute;
    bottom: 2rem;
    left: 0;
  `)};
`;

const CollectionName = withRouter(connect(null)(CollectionNameWrapper));

export { CollectionName };
