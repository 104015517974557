import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { ICollection } from "../../types/ICollection";
import MetaShare from "../MetaShare";
import { CollectionName } from "../shared/CollectionName";
import { HomeGalleryContainer } from "./HomeGalleryContainer";

interface IProps extends RouteComponentProps {
  currentCollection: ICollection | null;
}

export function Home({ currentCollection }: IProps) {
  return (
    <>
      <MetaShare title="Lola Valentina" />
      <View>
        <HomeGalleryContainer />
      </View>
      {currentCollection && <CollectionName collection={currentCollection} />}
    </>
  );
}

const View = styled.div`
  padding: 5rem 0 0;
  flex: 1 0 100%;
  width: 100vw;
  display: flex;
`;
