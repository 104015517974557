import defaultTo from "ramda/es/defaultTo";
import * as React from "react";
import { IMedia } from "../../types/IMedia";
import styled from "styled-components";
import { IPortfolio } from "../../types/IPortfolio";
import { PorfolioCollectionName } from "./PortfolioCollectionName";
import { PortfolioGallery } from "./PortfolioGallery";

interface IProps {
  item: IPortfolio;
  media: IMedia[];
}

class PortfolioSliderItem extends React.PureComponent<IProps> {
  public render() {
    const { item, media } = this.props;

    const { color, gallery, collection: collections } = item;
    const defaultToEmptyCollection = defaultTo({
      post_name: "",
      post_title: ""
    });
    const collection = defaultToEmptyCollection(collections[0]);

    return (
      <PortfolioSliderItemView color={color}>
        {gallery && <PortfolioGallery items={gallery} media={media} />}
        <PorfolioCollectionName
          slug={collection.post_name}
          title={collection.post_title}
        />
      </PortfolioSliderItemView>
    );
  }
}

const PortfolioSliderItemView = styled.div<{ color: string }>`
  width: 100%;
  height: 90vh;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  @media (min-width: 425px) {
    position: sticky;
    top: -1px;
    height: 100vh;
  }
`;

export { PortfolioSliderItem };
