import axios from "axios";
import R_pathOr from "ramda/es/pathOr";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { updateAppBgColor } from "../../actions/app";
import { ie11 } from "../../utils";
import { Colors } from "../../utils/Colors";
import MetaShare from "../MetaShare";
import { PageTitle } from "../shared/PageTitle";

interface IProps {
  page: string;
  updateAppBgColor: (color: string) => void;
}

const pathToTitle = R_pathOr("", ["title", "rendered"]);
const pathToContent = R_pathOr("", ["content", "rendered"]);

function View({ page: slug, updateAppBgColor }: IProps) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const load = async () => {
      const { data } = await axios({
        url: "/wp-json/wp/v2/pages",
        params: {
          slug
        }
      });

      const [page] = data;

      setContent(pathToContent(page));
      setTitle(pathToTitle(page));
    };

    updateAppBgColor(Colors.PALE_RED);
    load();
  }, [slug, updateAppBgColor]);

  return (
    <>
      <MetaShare title={`Lola Valentina - ${title}`} />
      <PageView>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </PageView>
      <PageTitle title={`${title}`} />
    </>
  );
}

const Page = connect(null, {
  updateAppBgColor
})(View);

export { Page };

export const PageView = styled.div`
  font-size: 1.17em;

  > div p {
    user-select: all;
  }

  flex: 1 0 100%;
  padding: 0 4rem 2rem;
  text-align: center;
  order: 2;

  > div {
    width: 100%;
    column-count: 1;
  }

  p {
    margin: 0.3rem 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 425px) {
    order: 1;
    display: flex;
    padding: 10rem 0 0;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;

    > div {
      padding: 0;
      width: 60%;
      margin-top: 0;
      column-count: 2;
      column-gap: 5rem;
    }
  }

  ${ie11(css`
    > div {
      width: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  `)};
`;
