import { AnyAction } from "redux";
import {
  FETCH_MEDIA_BEGIN,
  FETCH_MEDIA_FAILURE,
  FETCH_MEDIA_SUCCESS
} from "../actions/media";
import { IMedia } from "../types/IMedia";

export interface IMediaState {
  error: any;
  items: IMedia[];
  loading: boolean;
}

const initialState: IMediaState = {
  error: null,
  items: [],
  loading: false
};

export const media = (
  state = initialState,
  { type, payload }: AnyAction & { payload: { media: IMedia[]; error: any } }
): IMediaState => {
  switch (type) {
    case FETCH_MEDIA_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_MEDIA_SUCCESS:
      const list: IMedia[] = payload.media;

      return {
        ...state,
        items: list,
        loading: false
      };

    case FETCH_MEDIA_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
