import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IPost } from "../../types/IPost";
import { truncate } from "../../utils";
import { Colors } from "../../utils/Colors";
import { BackgroundImage } from "../shared/BackgroundImage";
import { BackgroundNoImage } from "../shared/BackgroundNoImage";

interface IBlogItem {
  post: IPost;
  image: string;
}

export function BlogItem({ post, image }: IBlogItem) {
  const [preview, setPreview] = useState(false);

  const { date, title, link, excerpt } = post;
  const { pathname } = new URL(link);
  const body = truncate(100, excerpt.rendered);

  return (
    <BlogItemView
      onMouseOver={() => setPreview(true)}
      onMouseOut={() => setPreview(false)}
      preview={preview}
    >
      <article>
        <time>{date}</time>
        <h2>{title.rendered}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: body
          }}
        />
        <Link to={`${pathname}`}>Read More</Link>
      </article>
      {image ? (
        <BackgroundImage src={image} />
      ) : (
        <BackgroundNoImage color="rgba(255, 255, 255, 0.2)" />
      )}
    </BlogItemView>
  );
}

const BlogItemView = styled.li<{ preview: boolean }>`
  position: relative;
  flex: 0 1 100%;
  padding: 0.5rem;

  > article {
    display: block;
    position: absolute;
    top: 4rem;
    left: 1.5rem;
    bottom: 4rem;
    right: 1.5rem;
    padding: 1.2rem;
    background-color: ${Colors.PALE_PINK};

    > time {
    }

    > h2 {
      font-family: "NittiGrotesk-Medium";
      text-transform: uppercase;
    }

    > div {
      margin: 1rem 0;

      > p {
        font-size: 1.1rem;
      }
    }

    > a:hover {
      font-weight: bold;
    }
  }

  @media (min-width: 425px) {
    flex: 0 1 33.33%;
    > article {
      display: ${({ preview }) => (preview ? "block" : "none")};
      background-color: ${Colors.PALE_PINK};
    }
  }

  img {
    width: 100%;
  }
`;
