import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { fetchPortfolio } from "../../actions/portfolio";
import { IStore } from "../../reducers";
import { Portfolio } from "./Portfolio";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  portfolio: state.portfolio.items
});

const mapDispatchToProps = {
  fetchPortfolio,
  updateAppBgColor
};

const PortfolioContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Portfolio)
);

export { PortfolioContainer };
