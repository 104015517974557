import { compose, flatten, map, uniq } from "ramda";
import * as React from "react";
import { useMemo, useState } from "react";
import { IMedia } from "../../types/IMedia";
import { IPortfolio } from "../../types/IPortfolio";

interface IProps {
  children?: JSX.Element[];
  media: IMedia[];
  portfolio: IPortfolio[];
  fetchPortfolio: () => void;
  fetchMedia: (listIds: string[], cache: IMedia[]) => void;
}

export function PortfolioLoader({
  children,
  media,
  portfolio,
  fetchPortfolio,
  fetchMedia
}: IProps) {
  const [isLoading, setIsLoading] = useState(true);

  useMemo(() => {
    fetchPortfolio();
  }, [fetchPortfolio]);

  useMemo(() => {
    if (portfolio.length > 0) {
      const uniqueMediaIds = compose<
        IPortfolio[],
        string[][],
        string[],
        string[]
      >(
        uniq,
        flatten,
        map((x: IPortfolio) => x.gallery.map(g => g.ID))
      );

      fetchMedia(uniqueMediaIds(portfolio), media);
      setIsLoading(false);
    }
  }, [portfolio, fetchMedia, media]);

  return isLoading ? <div>loading</div> : <>{children}</>;
}
