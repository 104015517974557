import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { IStore } from "../../reducers";
import { Lookbook } from "./Lookbook";

const mapStateToProps = (state: IStore) => ({
  collections: state.collections.items,
  media: state.media.items,
  lookbook: state.lookbook.items
});

const mapDispatchToProps = {
  updateAppBgColor
};

const LookbookContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Lookbook)
);

export { LookbookContainer };
