import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IProps {
  slug: string;
  title: string;
}

export function PorfolioCollectionName({ slug, title }: IProps) {
  return (
    <View>
      <Link to={`/collection/${slug}`}>{title}</Link>
    </View>
  );
}

const View = styled.div`
  width: 100%;
  margin: 2rem;

  font-size: 1.1rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.1rem;

  @media (min-width: 425px) {
    position: absolute;
    bottom: 2rem;
    z-index: 1;
    margin: 0;
    font-size: 1.2rem;
  }
`;
