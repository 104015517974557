import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { IStore } from "../../reducers";
import { Products } from "./Products";

const mapStateToProps = (state: IStore) => ({
  categories: state.productCategories.items,
  collections: state.collections.items,
  media: state.media.items,
  products: state.products.items
});

const mapDispatchToProps = {
  updateAppBgColor
};

const ProductsContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Products)
);

export { ProductsContainer };
