//#region
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IProduct } from "../../types/IProduct";
import { isMobile } from "../../utils";
import { Colors } from "../../utils/Colors";
import { BackgroundImage } from "../shared/BackgroundImage";
import { Download } from "../shared/Download";

interface IProps {
  product: IProduct;
  mediaUrl: string;
  collectionSlug: string;
  collectionTitle: string;
}
//#endregion

export function ProductGalleryItem({
  collectionSlug,
  collectionTitle,
  mediaUrl,
  product
}: IProps) {
  const [preview, setPreview] = useState(false);
  const toggle = (value: boolean) => () => setPreview(value);

  return isMobile() ? (
    <ProductGalleryItemView preview={false}>
      <Link to={`/collection/${collectionSlug}`}>
        <BackgroundImage src={mediaUrl} />
      </Link>
    </ProductGalleryItemView>
  ) : (
    <ProductGalleryItemView
      onMouseOver={toggle(true)}
      onMouseOut={toggle(false)}
      preview={preview}
    >
      <article>
        <h2>{collectionTitle}</h2>
        <ul>
          <li>{product.title.rendered}</li>
        </ul>
        {/* FIXME: post_name should be the slug */}
        <Link to={`/collection/${collectionSlug}`}>View collection</Link>
        <Link target="_blank" to={mediaUrl}>
          <Download />
        </Link>
      </article>
      <BackgroundImage src={mediaUrl} />
    </ProductGalleryItemView>
  );
}

// #region views
const ProductGalleryItemView = styled.li<{ preview: boolean }>`
  padding-top: 146%;
  position: relative;
  margin: 1em 1em 0;
  transition: background-color 1s 200ms;
  background-color: transparent !important;

  ${BackgroundImage} {
    position: absolute;
    z-index: 1;
    top: 0;
  }

  &:last-child {
    margin: 1em 1em;
  }

  > article {
    display: ${({ preview }) => (preview ? "flex" : "none")};
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1.2rem;
    flex-direction: column;
    justify-content: center;
    background-color: ${Colors.WHITE};

    > h2 {
      font-size: 1.1rem;
      text-align: center;
      text-transform: uppercase;
    }

    > ul {
      margin: 1.2rem 0 0;
      font-size: 0.9rem;
      li {
        text-transform: uppercase;
        line-height: 1.3rem;
      }
    }

    /* view collection, download links */
    a {
      margin: 0.4rem 0;
      text-transform: uppercase;
    }

    /* download link */
    a:nth-last-of-type(1) {
      position: relative;
      margin: 0.8rem 0;
      height: 2.25em;
      img {
        width: 1em;
        pointer-events: none;
      }
      &:hover img {
        opacity: 0;
      }
      &:hover:after {
        pointer-events: none;
        width: 100%;
        position: absolute;
        content: "Download this image";
        font-weight: normal;
        left: 0;
        top: 0;
      }
    }

    a:hover {
      font-weight: bold;
    }
  }

  img {
    width: 100%;
  }

  @media (min-width: 425px) {
    width: 100%;
    margin: 0 auto;

    &:last-child {
      margin: 0 auto;
    }
  }
`;
// #endregion
