import { connect } from "react-redux";
import { closeMenu } from "../../actions/menu";
import { HeaderTitle } from "./HeaderTitle";

const mapDispatchToProps = {
  closeMenu
};

const HeaderTitleContainer = connect(
  null,
  mapDispatchToProps
)(HeaderTitle);

export { HeaderTitleContainer };
