import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware
} from "connected-react-router";
import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { App } from "./components/App";
import "./fonts";
import { Fonts } from "./fonts";
import { backgroundSwitcher } from "./middlewares/background";
import rootReducer from "./reducers";
import UrlUtils from "./utils/UrlUtils";

console.log(`Lola Valentina - ${process.env.REACT_APP_VERSION}`);

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();

// eslint-disable-next-line no-restricted-globals
const queryIsPreview = UrlUtils.queryMap(location).get("preview") === "yes";
// eslint-disable-next-line no-restricted-globals
const queryIsNotPreview = UrlUtils.queryMap(location).get("preview") === "no";
const sessionIsPreview = Boolean(sessionStorage.getItem("preview"));

if (queryIsNotPreview) {
  sessionStorage.clear();
} else if (queryIsPreview || sessionIsPreview) {
  sessionStorage.setItem("preview", "true");
}

const isAppPreview = Boolean(sessionStorage.getItem("preview"));

const store = createStore(
  connectRouter(history)(rootReducer({ isAppPreview })),
  compose(
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), thunk, backgroundSwitcher)
    )
  )
);

ReactDOM.render(
  <>
    <Fonts />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </>,
  document.getElementById("root") as HTMLElement
);
