import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { fetchBlogPost } from "../../actions/blog";
import { IStore } from "../../reducers";
import { BlogPostItem } from "./BlogPostItem";

const mapStateToProps = (state: IStore) => ({
  current: state.blog.current,
  media: state.media.items
});

const mapDispatchToProps = {
  fetchBlogPost,
  updateAppBgColor
};

const BlogPostItemContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BlogPostItem)
);

export { BlogPostItemContainer };
