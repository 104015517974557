import { connect } from "react-redux";
import { withRouter } from "react-router";
import { closeMenu, openMenu } from "../../actions/menu";
import { fetchProductCategories } from "../../actions/product_categories";
import { IStore } from "../../reducers";
import { Menu } from "./Menu";

const mapStateToProps = (state: IStore, ownProps: any) => ({
  isOpen: state.menu.isOpen,
  isAppPreview: state.isAppPreview,
  menu: state.menu,
  bgColor: state.app.bgColor,
  categories: state.productCategories.items,
  collections: state.collections.items
});

const mapDispatchToProps = {
  closeMenu,
  fetchProductCategories,
  openMenu
};

const MenuContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Menu)
);

export { MenuContainer };
