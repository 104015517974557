import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchMedia } from "../../actions/media";
import { fetchTextiles } from "../../actions/textiles";
import { IStore } from "../../reducers";
import { TextilesLoader } from "./TextilesLoader";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  textiles: state.textiles.items
});

const mapDispatchToProps = {
  fetchTextiles,
  fetchMedia
};

const TextilesLoaderContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TextilesLoader) as any);

export { TextilesLoaderContainer };
