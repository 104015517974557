import * as React from "react";
import { ie11 } from "../../utils";
import styled, { css } from "styled-components";

const PageTitleView = ({
  className,
  title
}: {
  className?: string;
  title: string;
}) => <h2 className={className}>{title}</h2>;

const PageTitle = styled(PageTitleView)`
  flex: 0 0 100%;
  display: block;
  margin: 3.4rem 0;
  padding: 0;
  width: 100%;

  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.1rem;

  order: 1;

  @media (min-width: 425px) {
    margin: 0 0 3.4rem;
    order: 2;
  }

  ${ie11(css`
    margin: 3.4rem 0;
  `)};
`;

export { PageTitle };
