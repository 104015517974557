import { AnyAction } from "redux";
import { CLOSE_MENU, OPEN_MENU } from "../actions/menu";

export interface IMenuState {
  isOpen: boolean;
}

const initialState: IMenuState = {
  isOpen: false
};

export const menu = (state = initialState, { type }: AnyAction): IMenuState => {
  switch (type) {
    case OPEN_MENU:
      return {
        ...state,
        isOpen: true
      };

    case CLOSE_MENU:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
};
