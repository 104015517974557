import compose from "ramda/src/compose";
import head from "ramda/src/head";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import reject from "ramda/src/reject";
import uniqBy from "ramda/src/uniqBy";
import { AnyAction } from "redux";
import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS
} from "../actions/products";
import { IProduct } from "../types/IProduct";
import { IProductCategory } from "../types/IProductCategory";

const firstCategory = compose<
  IProduct,
  IProductCategory[] | false,
  IProductCategory
>(
  head,
  prop("product_categories")
);

const withoutCategory = (x: IProduct) => x.product_categories === false;

const uniqCategories = compose<
  IProduct[],
  IProduct[],
  IProductCategory[],
  IProductCategory[]
>(
  uniqBy(prop("id")),
  map(firstCategory),
  reject(withoutCategory)
);

export interface IProductState {
  error: any;
  products: IProduct[];
  items: IProduct[];
  categories: IProductCategory[];
  filteredCategory: string;
  loading: boolean;
}

const initialState: IProductState = {
  error: null,
  categories: [],
  products: [],
  items: [],
  filteredCategory: "",
  loading: false
};

export const products = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & {
    payload: {
      products: IProduct[];
      error: any;
      location: { pathname: string };
    };
  }
): IProductState => {
  switch (type) {
    case FETCH_PRODUCTS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_PRODUCTS_SUCCESS:
      const categories: IProductCategory[] = uniqCategories(payload.products);

      return {
        ...state,
        products: payload.products,
        items: payload.products,
        categories,
        loading: false
      };

    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
