import { AnyAction } from "redux";
import { Colors } from "../utils/Colors";
import { UPDATE_APP_BG_COLOR } from "../actions/app";

export interface IAppState {
  bgColor: string;
}

const initialState: IAppState = {
  bgColor: Colors.PALE_BLUE
};

export const app = (
  state = initialState,
  { type, payload }: AnyAction
): IAppState => {
  switch (type) {
    case UPDATE_APP_BG_COLOR:
      return {
        ...state,
        bgColor: payload
      };

    default:
      return state;
  }
};
