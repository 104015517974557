import * as React from "react";
import { useRef, useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/css/swiper.css";
import styled from "styled-components";
import { IMedia } from "../../types/IMedia";
import { IPortfolioGallery } from "../../types/IPortfolio";
import { PortfolioGalleryItem } from "./PortfolioGalleryItem";

interface IProps {
  items: IPortfolioGallery[];
  media: IMedia[];
}

interface ISwiper {
  swiper: {
    isEnd: boolean;
    isBeginning: boolean;
    slideNext: () => VoidFunction;
    slidePrev: () => VoidFunction;
  };
}

export function PortfolioGallery({ items, media }: IProps) {
  const swiperInstance = useRef<ISwiper>();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const goNext = () => {
    if (swiperInstance.current && !swiperInstance.current.swiper.isEnd) {
      setActiveIndex(i => i + 1);
      swiperInstance.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperInstance.current && !swiperInstance.current.swiper.isBeginning) {
      setActiveIndex(i => i - 1);
      swiperInstance.current.swiper.slidePrev();
    }
  };

  const params = {
    loop: false,
    speed: 600,
    slidesPerView: 1,
    preloadImages: true,
    updateOnImagesReady: true
  };

  const isFirst = activeIndex > 0;
  const isLast = activeIndex < items.length - 1;

  return (
    <View>
      {isFirst && <BtnPrev onClick={goPrev}>PREV</BtnPrev>}
      <Swiper {...params} ref={swiperInstance}>
        {items.map((x: IPortfolioGallery, index: number) => (
          <div key={index}>
            <PortfolioGalleryItem item={x} media={media} />
          </div>
        ))}
      </Swiper>
      {isLast && <BtnNext onClick={goNext}>NEXT</BtnNext>}
    </View>
  );
}

const Btn = styled.a`
  display: none;
  position: absolute;
  font-size: 1.6rem;
  z-index: 1;
  top: 50%;
  cursor: pointer;

  @media (min-width: 425px) {
    display: block;
  }
`;

const BtnPrev = styled(Btn)`
  left: 44px;
`;

const BtnNext = styled(Btn)`
  right: 44px;
`;

const View = styled.div`
  width: 100vw;
  height: 66vw;
  overflow: hidden;

  > div {
    overflow: hidden;
  }

  @media (min-width: 425px) {
    width: 60vw;
    height: 36vw;
    max-height: 68vh;

    > div {
      height: 36vw;
      max-height: 68vh;
    }
  }
`;
