import compose from "ramda/es/compose";
import pathOr from "ramda/es/pathOr";
import prop from "ramda/es/prop";

const origin = compose<object, string, URL, string>(
  prop("origin"),
  (x: string) => new URL(x),
  pathOr("", ["location", "href"])
);

const location = (d: object) =>
  pathOr<string, string>("", ["location", "href"], d);

const queryMap = (location: Location) => {
  const query = location.search
    .replace("?", "")
    .split("&")
    .map(x => x.split("="))
    .map<[string, string]>(i => [i[0], i[1]]);

  return new Map(query);
};

export default {
  origin,
  location,
  queryMap
};
