export enum Colors {
  BLACK = "#000",
  BRICK = "#A8483D",
  GREY = "#E9E6DA",
  NAVY_BLUE = "#00263A",
  PALE_BLUE = "#BECCD5",
  PALE_PINK = "#E5D4D3",
  PALE_RED = "#D7AA94",
  TURQUOISE = "#78DED4",
  YELLOW = "#FDD756",
  WHITE = "#FFF"
}
