//#region imports
import compose from "ramda/src/compose";
import curry from "ramda/src/curry";
import dec from "ramda/src/dec";
import inc from "ramda/src/inc";
import map from "ramda/src/map";
import reject from "ramda/src/reject";
import path from "ramda/src/path";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/css/swiper.css";
import ReactImageMagnify from "react-image-magnify";
import Lightbox from "react-images";
import { IMedia } from "../../types/IMedia";
import { isMobile } from "../../utils";
import { Colors } from "../../utils/Colors";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { ICollection } from "../../types/ICollection";
import { IProduct } from "../../types/IProduct";
import { CollectionGalleryImageItem } from "./CollectionGalleryImageItem";
import { params } from "./galleryParams";
//#endregion
//#region interfaces
interface IProps {
  collection: ICollection;
  media: IMedia[];
}
//#endregion

const zeroOrMore = (x: number) => Math.max(0, dec(x));
const totalOrLess = curry((t: number, x: number) => Math.min(t, inc(x)));

const onlyVisibles = reject(
  (x: IProduct) => !Boolean(parseInt(x.visible_in_collections, 10))
);

const url = (from: IMedia[], guid: string) =>
  imageUrl(findMediaByGUID(guid, from));

const toLightboxImage = curry((media: IMedia[], guid: string) => ({
  src: url(media, guid)
}));

const toMagnifyParams = (media: IMedia[], guid: string) => ({
  enlargedImagePosition: "over",
  smallImage: {
    alt: "",
    isFluidWidth: true,
    src: url(media, guid)
  },
  largeImage: {
    src: url(media, guid),
    width: 1200,
    height: 1200
  }
});

const LIGHTBOX_THEME = {
  close: {
    fill: Colors.BLACK,
    position: "fixed",
    top: "44px",
    right: "44px"
  },
  container: {
    zIndex: 9999999
  },
  footer: {
    display: "none"
  }
};

export function CollectionGallery({
  collection: {
    title,
    collection_color: collectionColor,
    collection_description: description,
    collection_products: all
  },
  media
}: IProps) {
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const swiper = useRef();

  const allProducts = onlyVisibles(all as IProduct[]);
  const toLightboxImages = map(toLightboxImage(media));
  const toGUID = allProducts.map(x => x.product_gallery.ID);
  const images = toLightboxImages(toGUID);
  const totalImages = images.length;

  useEffect(() => {
    try {
      // @ts-ignore
      swiper.current.swiper.update();
    } catch (error) {
      //
    }
  });

  const handleClickPrev = (idx: number) => () => {
    const updateIndex = compose(
      setLightboxIndex,
      zeroOrMore
    );

    updateIndex(idx);
  };

  const handleClickNext = (idx: number, total: number) => () => {
    const updateIndex = compose(
      setLightboxIndex,
      totalOrLess(total)
    );

    updateIndex(idx);
  };

  const handleClose = () => setLightboxIsOpen(false);

  const handleClickImage = (idx: number) => () => {
    setLightboxIsOpen(true);
    setLightboxIndex(idx);
  };

  const descriptionNode: JSX.Element[] = [
    <CollectionDescription key="description">
      <h2>{title.rendered}</h2>
      <p>{description}</p>
    </CollectionDescription>
  ];

  const contentNode = allProducts.map((x, index) => {
    const imageId = path(["product_gallery", "ID"], x) as string;
    const magnifyParams = toMagnifyParams(media, imageId);

    return (
      <CollectionGalleryImageItem key={index} onClick={handleClickImage(index)}>
        <ReactImageMagnify {...magnifyParams} />
      </CollectionGalleryImageItem>
    );
  });

  return (
    <View>
      {isMobile() ? (
        [descriptionNode, contentNode]
      ) : (
        <>
          <LightBoxTheme collectionColor={collectionColor} />
          <Lightbox
            backdropClosesModal={true}
            currentImage={lightboxIndex}
            images={images}
            isOpen={lightboxIsOpen}
            onClickPrev={handleClickPrev(lightboxIndex)}
            onClickNext={handleClickNext(lightboxIndex, totalImages)}
            leftArrowTitle="PREV"
            rightArrowTitle="NEXT"
            onClose={handleClose}
            theme={LIGHTBOX_THEME}
          />
          <Swiper {...params} ref={swiper}>
            {[descriptionNode, contentNode]}
          </Swiper>
        </>
      )}
    </View>
  );
}

//#region Styled components
const CollectionDescription = styled.div`
  font-size: 1.1rem;
  padding: 0 2rem;
  align-self: center;
  text-align: center;

  @media (min-width: 425px) {
    padding: 0 4rem;
    text-align: left;
  }

  h2 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.1rem;
    margin-bottom: 1.7rem;
  }

  p {
    line-height: 1.5rem;
  }

  &.swiper-slide {
    width: 30%;
    min-width: 300px;
  }
`;

const View = styled.div`
  width: 100vw;
  margin: 4.4rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 425px) {
    flex-direction: row;
  }
`;

const LightBoxTheme = createGlobalStyle<{ collectionColor: string }>`
  #lightboxBackdrop {
    background: ${({ collectionColor }) => collectionColor};
    button[class^="arrow_"] {
      padding: 0;
      text-align: right;
      margin: 0;
      height: auto;
      width: auto;
      position: absolute;
      span {
        display: none;
      }
      &:before {
        content: "";
        display: block;
        position: relative;
        font-size: 1.1rem;
      }
    }

    button[class*="arrow__direction__right"] {
      right: 44px;
      &:before {
        content: "NEXT";
      }
    }

    button[class*="arrow__direction__left"] {
      left: 44px;
      &:before {
        content: "PREV";
      }
    }
  }
`;
//#endregion
