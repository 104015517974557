import * as React from "react";
import { Route } from "react-router-dom";
import { BlogContainer } from "./blog/BlogContainer";
import { BlogPostItemContainer } from "./blog/BlogPostItemContainer";
import { CollectionsContainer } from "./collections/CollectionsContainer";
import { CollectionsListContainer } from "./collections/CollectionsListContainer";
import { CollectionsLoaderContainer } from "./collections/CollectionsLoaderContainer";
import { ContactContainer } from "./contact/ContactContainer";
import { CustomizerContainer } from "./customizer/CustomizerContainer";
import { DataLoaderContainer } from "./DataLoaderContainer";
import { HeaderTitleContainer } from "./header-title/HeaderTitleContainer";
import { HomeContainer } from "./home/HomeContainer";
import { LookbookContainer } from "./lookbook/LookbookContainer";
import { LookbookLoaderContainer } from "./lookbook/LookbookLoaderContainer";
import { MenuContainer } from "./menu/MenuContainer";
import { Page } from "./common/Page";
import { PortfolioContainer } from "./portfolio/PortfolioContainer";
import { PortfolioLoaderContainer } from "./portfolio/PortfolioLoaderContainer";
import { ProductsContainer } from "./products/ProductsContainer";
import { ProductsLoaderContainer } from "./products/ProductsLoaderContainer";
import "./styles.css";
import { TextilesContainer } from "./textiles/TextilesContainer";
import { TextilesLoaderContainer } from "./textiles/TextilesLoaderContainer";

const HomeView = () => <HomeContainer />;
const CollectionsView = () => (
  <CollectionsLoaderContainer>
    <CollectionsContainer />
  </CollectionsLoaderContainer>
);
const CollectionsListView = () => <CollectionsListContainer />;
const LookbookView = () => (
  <LookbookLoaderContainer>
    <LookbookContainer />
  </LookbookLoaderContainer>
);
const TextilesView = () => (
  <TextilesLoaderContainer>
    <TextilesContainer />
  </TextilesLoaderContainer>
);
const ProductsView = () => (
  <ProductsLoaderContainer>
    <ProductsContainer />
  </ProductsLoaderContainer>
);
const PortfolioView = () => (
  <PortfolioLoaderContainer>
    <PortfolioContainer />
  </PortfolioLoaderContainer>
);
const CustomizerView = () => <CustomizerContainer />;
const AboutView = () => <Page page="about-us" />;
const WhatWeDoView = () => <Page page="what-we-do" />;
const HowWeWorkView = () => <Page page="how-we-work" />;
const ContactView = () => <ContactContainer />;
const BlogView = () => <BlogContainer />;
const BlogPostItemView = () => <BlogPostItemContainer />;

const App = () => (
  <>
    <HeaderTitleContainer />
    <DataLoaderContainer>
      <MenuContainer />
      <Route exact={true} path="/" render={HomeView} />
      <Route exact={true} path="/collection/" render={CollectionsListView} />
      <Route
        exact={true}
        path="/collection/:collection_slug/"
        render={CollectionsView}
      />
      <Route exact={true} path="/product/" render={ProductsView} />
      <Route
        exact={true}
        path="/product/:category_slug/"
        render={ProductsView}
      />
      <Route path="/textiles/" render={TextilesView} />
      <Route path="/lookbook/" render={LookbookView} />
      <Route path="/gallery/" render={PortfolioView} />
      <Route exact={true} path="/custom-your-table/" render={CustomizerView} />
      <Route exact={true} path="/about-us/" render={AboutView} />
      <Route exact={true} path="/what-we-do/" render={WhatWeDoView} />
      <Route exact={true} path="/how-we-work/" render={HowWeWorkView} />
      <Route exact={true} path="/contact-us/" render={ContactView} />
      <Route exact={true} path="/blog/" render={BlogView} />
      <Route
        exact={true}
        path="/:year/:month/:day/:slug/:post_id/"
        render={BlogPostItemView}
      />
    </DataLoaderContainer>
  </>
);

export { App };
