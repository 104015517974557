import { connect } from "react-redux";
import { updateAppBgColor } from "../../actions/app";
import { Contact } from "./Contact";

const mapDispatchToProps = {
  updateAppBgColor
};

const ContactContainer = connect(
  null,
  mapDispatchToProps
)(Contact);

export { ContactContainer };
