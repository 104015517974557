import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ICollection } from "../../types/ICollection";
import { IMedia } from "../../types/IMedia";
import { isMobile } from "../../utils";
import { Colors } from "../../utils/Colors";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import styled from "styled-components";

interface IProps {
  categoriesInCollection: string[];
  collection: ICollection;
  media: IMedia[];
}

export function CollectionsListGalleryItem({
  categoriesInCollection,
  collection: { title, collection_featured_image, slug },
  media
}: IProps) {
  const [preview, setPreview] = useState(false);
  const toggle = (value: boolean) => () => setPreview(value);
  const img = imageUrl(findMediaByGUID(collection_featured_image.guid, media));
  const alt = title.rendered;

  return isMobile() ? (
    <CollectionsListGalleryItemView preview={false}>
      <Link to={`/collection/${slug}`}>
        <img src={img} alt={alt} />
      </Link>
    </CollectionsListGalleryItemView>
  ) : (
    <CollectionsListGalleryItemView
      onMouseOver={toggle(true)}
      onMouseOut={toggle(false)}
      preview={preview}
    >
      <article>
        <h2>{alt}</h2>
        <ul>
          {categoriesInCollection.map((x, idx) => (
            <li key={idx}>{x}</li>
          ))}
        </ul>
        <Link to={`/collection/${slug}`}>View collection</Link>
      </article>
      <img src={img} alt={alt} />
    </CollectionsListGalleryItemView>
  );
}

// #region views
const CollectionsListGalleryItemView = styled.li<{ preview: boolean }>`
  position: relative;
  margin: 1em 1em 0;

  &:last-child {
    margin: 1em 1em;
  }

  > article {
    display: ${({ preview }) => (preview ? "flex" : "none")};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1.2rem;
    flex-direction: column;
    justify-content: center;
    background-color: ${Colors.WHITE};

    > h2 {
      font-size: 1.1rem;
      text-align: center;
      text-transform: uppercase;
    }

    > ul {
      margin: 0.8rem 0 2.3rem 0;
      font-size: 0.9rem;
      li {
        text-transform: uppercase;
        line-height: 1.3rem;
      }
    }

    > a {
      text-transform: uppercase;
    }

    > a:hover {
      font-weight: bold;
    }
  }

  img {
    width: 100%;
  }

  @media (min-width: 425px) {
    width: 100%;
    margin: 0 auto;

    &:last-child {
      margin: 0 auto;
    }
  }
`;
// #endregion
