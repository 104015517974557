import * as React from "react";
import styled, { css } from "styled-components";
import { ITextile } from "../../types/ITextile";
import { IMedia } from "../../types/IMedia";
import { ie11 } from "../../utils";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import { TextilesGalleryItem } from "./TextilesGalleryItem";

interface IProps {
  textiles: ITextile[];
  media: IMedia[];
}

export function TextilesGallery({ textiles, media }: IProps) {
  return (
    <TextilesGalleryView>
      <ul>
        {textiles.map((item: ITextile, index: number) => {
          const m = findMediaByGUID(item.image.ID, media);
          const mediaUrl = imageUrl(m);

          return (
            <TextilesGalleryItem
              key={index}
              textile={item}
              mediaUrl={mediaUrl}
            />
          );
        })}
      </ul>
    </TextilesGalleryView>
  );
}

export const TextilesGalleryView = styled.div`
  width: 100%;

  > ul {
    display: flex;
    flex-direction: column;

    > li {
      padding-top: 100%;
      transition: background-color 1s 200ms;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  @media (min-width: 425px) {
    max-width: 58rem;

    > ul {
      width: 100%;
      margin: 4.4rem auto;
      display: grid;
      grid-template-columns: 49% 49%;
      grid-gap: 1.2rem 2%;
    }
  }

  ${ie11(css`
    > ul {
      > li {
        border: 10px solid transparent;
        width: 49%;
        float: left;
      }
    }
  `)};
`;
