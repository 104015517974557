import { UPDATE_APP_BG_COLOR } from "../actions/app";

/**
 * This middleare intercepts the UPDATE_APP_BG_COLOR
 * and change the body background color anytime the
 * action is dispatched.
 */
export const backgroundSwitcher = () => (next: any) => (action: any) => {
  if (action.type === UPDATE_APP_BG_COLOR) {
    const color: string = action.payload;
    document.body.style.backgroundColor = color;
  }

  return next(action);
};
