import * as React from "react";
import styled from "styled-components";

const MenuCollectionName = ({
  name,
  thumbnail
}: {
  name: string;
  thumbnail: string;
}) => <Wrapper thumbnail={thumbnail}>{name}</Wrapper>;

const Wrapper = styled.span<{ thumbnail: string }>`
  font-size: 1.1rem;
  position: relative;

  @media (min-width: 425px) {
    :hover:after {
      position: fixed;
      display: block;
      content: "";
      width: 200px;
      padding-top: 45%;
      background-image: url(${props => props.thumbnail});
      background-size: contain;
      background-repeat: no-repeat;
      left: 66vw;
    }
  }
`;

export { MenuCollectionName };
