import { compose, equals, filter, not, prop } from "ramda";
import * as React from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { ICollection } from "../../types/ICollection";
import { IProductCategory } from "../../types/IProductCategory";

const onlyWithProducts = compose<ICollection, boolean | any, boolean, boolean>(
  not,
  equals(false),
  prop("collection_products")
);

const rejectDisabled = (x: IProductCategory) => {
  switch (x.slug) {
    case "dinnerware":
    case "placemat":
    case "tablecloth":
      return false;
    default:
      return true;
  }
};

interface IProps extends RouteComponentProps {
  categories: IProductCategory[];
  collections: ICollection[];
}

export function VisibilityFilter({
  categories: unfilterCategories,
  collections: unfilterCollections
}: IProps) {
  const categories = filter(rejectDisabled, unfilterCategories);
  const collections = filter(onlyWithProducts, unfilterCollections);
  const loading: boolean = categories.length === 0 || collections.length === 0;

  return (
    <View isLoading={loading}>
      <ul>
        <li>
          <NavLink exact={true} activeClassName="active" to="/product">
            Products
          </NavLink>
        </li>
        <li>/</li>
        {categories.map((category: IProductCategory) => (
          <li key={category.id}>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`/product/${category.slug}/`}
            >
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <ul>
        <li>
          <NavLink to="/collection" exact={true} activeClassName="active">
            Collections
          </NavLink>
        </li>
        <li>/</li>
        {collections.map((collection: ICollection) => (
          <li key={collection.id}>
            <NavLink
              exact={true}
              activeClassName="active"
              to={`/collection/${collection.slug}/`}
            >
              {collection.title.rendered}
            </NavLink>
          </li>
        ))}
      </ul>
    </View>
  );
}

const View = styled.div<{ isLoading: boolean }>`
  ul {
    visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    width: 78%;
    flex-wrap: wrap;
    margin: 0 auto 0.7rem;

    > li {
      margin: 0 0.5rem;

      > a:hover,
      > a.active {
        font-weight: bold;
      }
    }
  }
`;
