import * as R from "ramda";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { createGlobalStyle, css } from "styled-components";
import { IMenuState } from "../../reducers/menu";
import { ICollection } from "../../types/ICollection";
import { IProductCategory } from "../../types/IProductCategory";
import { ie11 } from "../../utils";
import { Colors } from "../../utils/Colors";
import iconClose from "./icon-close.svg";
import iconMenu from "./icon-menu.svg";
import { MenuCollectionName } from "./MenuCollectionName";

interface IProps {
  bgColor: string;
  closeMenu: () => void;
  fetchProductCategories: () => void;
  className?: string;
  categories: IProductCategory[];
  collections: ICollection[];
  isAppPreview: boolean;
  isOpen: boolean;
  menu: IMenuState;
  openMenu: () => void;
}

const MENU = [
  { to: "/textiles/", label: "Patterns" },
  { to: "/lookbook/", label: "Lookbook" },
  { to: "/gallery/", label: "Gallery" },
  // { to: "/what-we-do/", label: "What we do" },
  { to: "/how-we-work/", label: "How we work" },
  // { to: "/custom-your-table/", label: "Design your table" },
  { to: "/about-us/", label: "About us" },
  { to: "/contact-us/", label: "Contact us" }
  // { to: "/blog/", label: "Blog" }
];

export function Menu({
  isOpen,
  categories,
  collections,
  closeMenu,
  fetchProductCategories,
  openMenu
}: IProps) {
  useEffect(() => {
    fetchProductCategories();
  }, [fetchProductCategories]);

  const [isCollectionsOpen, setIsCollectionsOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);

  const toggleCollectionOpen = (value: boolean) => () => {
    setIsCollectionsOpen(value);
    setIsProductsOpen(false);
  };

  const toggleProductsOpen = (value: boolean) => () => {
    setIsProductsOpen(value);
    setIsCollectionsOpen(false);
  };

  const toggle = (willOpen: boolean) => () => {
    if (willOpen) {
      openMenu();
    } else {
      setIsProductsOpen(false);
      setIsCollectionsOpen(false);
      closeMenu();
    }
  };

  const pathToFeaturedImage = R.pathOr("", [
    "collection_featured_image",
    "guid"
  ]);

  const rejectDisabled = (x: IProductCategory) => {
    switch (x.slug) {
      case "dinnerware":
      case "placemat":
      case "tablecloth":
        return false;
      default:
        return true;
    }
  };

  return (
    <>
      <Global />
      <MenuIcon onClick={toggle(!isOpen)}>
        <img alt="" src={isOpen ? iconClose : iconMenu} />
      </MenuIcon>
      <MenuView isOpen={isOpen} bgColor={Colors.PALE_RED}>
        <nav>
          <div>
            <div
              onClick={toggleCollectionOpen(!isCollectionsOpen)}
              className="level-1"
            >
              Collections
            </div>
            <SubMenu isOpen={isCollectionsOpen}>
              {collections.map((x: ICollection) => (
                <Link
                  key={x.id}
                  className="level-2"
                  onClick={toggle(false)}
                  to={`/collection/${x.slug}/`}
                >
                  <MenuCollectionName
                    name={x.title.rendered}
                    thumbnail={pathToFeaturedImage(x) as string}
                  />
                </Link>
              ))}
            </SubMenu>
          </div>
          <div>
            <div
              onClick={toggleProductsOpen(!isProductsOpen)}
              className="level-1"
            >
              Products
            </div>
            <SubMenu isOpen={isProductsOpen}>
              {categories.filter(rejectDisabled).map((x: IProductCategory) => (
                <Link
                  key={x.id}
                  className="level-2"
                  onClick={toggle(false)}
                  to={`/product/${x.slug}/`}
                >
                  <MenuProductCategory>{x.name}</MenuProductCategory>
                </Link>
              ))}
            </SubMenu>
          </div>
          {MENU.map((x, idx) => (
            <Link
              key={idx}
              className="level-1"
              onClick={toggle(false)}
              to={x.to}
            >
              {x.label}
            </Link>
          ))}
          <SocialLinks>
            <li>
              <a
                href="https://www.facebook.com/lolavalentinadesign"
                target="_blank"
                rel="noopener noreferrer"
              >
                FACEBOOK
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/lolavalentinadesigns/"
                target="_blank"
                rel="noopener noreferrer"
              >
                INSTAGRAM
              </a>
            </li>
          </SocialLinks>
        </nav>
      </MenuView>
    </>
  );
}

const SocialLinks = styled.ul`
  margin: 1.5rem 0;
  text-align: center;
  li {
    a {
      color: #000000;
      margin: 0.2rem 0;
      display: block;
      text-align: center;
      font-size: 1.4rem;
      text-transform: uppercase;
      &:hover {
        font-weight: bold;
      }
    }
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 999999;
  top: 1.2rem;
  right: 1.2rem;
  > img {
    height: 1rem;
    vertical-align: baseline;
  }

  @media (min-width: 425px) {
    position: absolute;
    top: 70px;
    right: 44px;
    > img {
      height: auto;
    }
  }

  @media (orientation: landscape) {
    top: 1.5rem;
  }
`;

const Global = createGlobalStyle`
body.header-fixed ${MenuIcon} {
  @media (min-width: 425px) {
    position: fixed;
  }
}
`;

MenuIcon.displayName = "MenuIcon";

const MenuView = styled.div<{ bgColor: string; isOpen: boolean }>`
  position: fixed;
  overflow: auto;
  z-index: 999997;
  pointer-events: ${props => (props.isOpen ? "all" : "none")};
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.bgColor};
  transform: ${props =>
    props.isOpen ? "translate(0, 0%)" : "translate(0, 100%)"};
  transition: all 800ms ease;
  padding-top: 0;
  justify-content: center;
  align-items: center;
  padding-top: 8em;

  @media (min-height: 32em) {
    padding-top: 0;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .level-1 {
      margin: 0.2rem 0;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 1.4rem;
      text-transform: uppercase;

      &:hover {
        font-weight: bold;
      }
    }

    .level-2 {
      margin: 0.2rem 0;
      display: block;
      width: 100%;
      text-align: center;
      text-transform: uppercase;

      &:hover {
        font-weight: bold;
      }
    }
  }

  ${ie11(css`
    padding-top: 10rem;
  `)};
`;

MenuView.displayName = "MenuView";

const SubMenu = styled.ul<{ isOpen: boolean }>`
  transition: all 300ms ease;
  overflow: hidden;
  height: auto;
  max-height: ${({ isOpen }) => (isOpen ? "200px" : 0)};
`;

SubMenu.displayName = "SubMenu";

const MenuProductCategory = styled.span`
  font-size: 1.1rem;
  position: relative;
`;

MenuProductCategory.displayName = "MenuProductCategory";
