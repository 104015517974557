import { flatten, uniq } from "ramda";
import * as React from "react";
import { useMemo } from "react";
import { ICollection } from "../../types/ICollection";
import { IMedia } from "../../types/IMedia";

interface IProps {
  children?: JSX.Element[];
  media: IMedia[];
  collections: ICollection[];
  fetchMedia: (listIds: string[], cache: IMedia[]) => void;
}

export function CollectionsLoader({
  children,
  media,
  collections,
  fetchMedia
}: IProps) {
  useMemo(() => {
    const pids = collections.map(c => {
      if (typeof c.collection_products === "boolean") {
        return [];
      }
      return c.collection_products.map(p => p.product_gallery.ID);
    });

    fetchMedia(uniq(flatten(pids)), media);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMedia, collections]);

  const isLoading = media.length === 0;
  return <div>{isLoading ? null : children}</div>;
}
