import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchMedia } from "../../actions/media";
import { fetchLookbook } from "../../actions/lookbook";
import { IStore } from "../../reducers";
import { LookbookLoader } from "./LookbookLoader";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  lookbook: state.lookbook.items,
  collections: state.collections.items
});

const mapDispatchToProps = {
  fetchLookbook,
  fetchMedia
};

const LookbookLoaderContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LookbookLoader) as any);

export { LookbookLoaderContainer };
