import find from "ramda/src/find";
import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { ICollection } from "../../types/ICollection";
import MetaShare from "../MetaShare";
import { VisibilityFilterContainer } from "../shared/VisibilityFilterContainer";
import { CollectionGalleryContainer } from "./CollectionGalleryContainer";

interface IProps extends RouteComponentProps<IParams> {
  collections: ICollection[];
  updateAppBgColor: (color: string) => void;
}

interface IParams {
  collection_slug: string;
}

interface IState {
  collection: ICollection;
}

class Collections extends React.PureComponent<IProps> {
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const { collections } = props;
    const collectionSlug: string = props.match.params.collection_slug;
    const collection: ICollection | undefined = find(
      (x: ICollection) => x.slug === collectionSlug,
      collections
    );

    if (collection !== state.collection) {
      if (collection) {
        props.updateAppBgColor(collection.collection_collection_color);
      }
      return {
        collection
      };
    }

    return null;
  }

  public state = {};

  public render() {
    const { collections } = this.props;
    const collectionSlug: string = this.props.match.params.collection_slug;

    const collection: ICollection | undefined = find(
      (x: ICollection) => x.slug === collectionSlug,
      collections
    );

    if (typeof collection === "undefined") {
      return (
        <View>{withoutProducts(<h3>Ups!, Collection not found.</h3>)}</View>
      );
    } else if (
      !Array.isArray(collection.collection_products) ||
      collection.collection_products.length === 0
    ) {
      return (
        <View>
          {withoutProducts(<h3>This collection has no products yet.</h3>)}
        </View>
      );
    } else {
      return (
        <>
          <MetaShare title="Lola Valentina - Collections" />
          <View>
            <div>
              <VisibilityFilterContainer />
              <CollectionGalleryContainer collection={collection} />
            </div>
          </View>
        </>
      );
    }
  }
}

const withoutProducts = (children: JSX.Element) => (
  <View>
    <div>
      {children}
      <Link to="/">Return to home</Link>
    </div>
  </View>
);

const View = styled.div`
  padding: 5rem 0 0;

  @media (min-width: 425px) {
    padding: 10.2rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export { Collections };
