import * as React from "react";
import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Logo } from "../shared/Logo";

const HeaderTitle = ({ closeMenu }: { closeMenu: () => void }) => (
  <>
    <Global />
    <HeaderTitleView>
      <Link onClick={closeMenu} to="/">
        <Logo />
      </Link>
    </HeaderTitleView>
  </>
);

const HeaderTitleView = styled.div`
  z-index: 999998;
  width: 100%;
  margin: 1.2rem 0 0 -1em;
  display: flex;
  justify-content: center;

  > a {
    flex: 0 0 auto;
    display: flex;
    &:hover {
      opacity: 0.8;
    }
  }

  @media (min-width: 425px) {
    position: absolute;
    text-align: center;
    left: 0;
    top: 4.3rem;
    flex: 0 0 100%;
    margin: 0;
  }

  @media (orientation: landscape) {
    top: 1.5rem;
  }
`;

const Global = createGlobalStyle`
  body.header-fixed ${HeaderTitleView} {
    @media (min-width: 425px) {
      position: fixed;
    }
  }
`;

export { HeaderTitle };
