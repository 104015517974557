import defaultTo from "ramda/es/defaultTo";
import * as React from "react";
import { Helmet } from "react-helmet";
import UrlUtils from "../utils/UrlUtils";

interface IProps {
  title?: string;
  description?: string;
  image?: string;
}

const doc = UrlUtils.origin(document);
const toTitle = defaultTo("Lola Valentina");
const toDescription = defaultTo("");
const toImage = defaultTo(doc + "/wp-content/themes/lola-valentina/lola.png");

export default function MetaShare({ title, description, image }: IProps) {
  return (
    <Helmet>
      <title>{toTitle(title)}</title>
      <meta content={toTitle(title)} property="og:title" />
      <meta content={toDescription(description)} property="og:description" />
      <meta content="website" property="og:type" />
      <meta content={doc} property="og:url" />
      <meta content="en_US" property="og:locale" />
      <meta content="Lola Valentina" property="og:site_name" />
      <meta content={toImage(image)} property="og:image" />
      <meta content="image/jpeg" property="og:image:type" />
      <meta content="325" property="og:image:width" />
      <meta content="325" property="og:image:height" />
    </Helmet>
  );
}
