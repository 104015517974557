import * as React from "react";
import { IMedia } from "../../types/IMedia";
import { IPortfolioGallery } from "../../types/IPortfolio";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import styled from "styled-components";
import { ImageLoader } from "../shared/ImageLoader";

interface IProps {
  item: IPortfolioGallery;
  media: IMedia[];
}

const View = styled(ImageLoader)`
  width: 100%;
`;

function PortfolioGalleryItem({ item, media }: IProps) {
  const images = findMediaByGUID(item.ID, media);
  const src = imageUrl(images, "large");

  return <View src={src} />;
}

export { PortfolioGalleryItem };
