import { AnyAction } from "redux";
import {
  FETCH_LOOKBOOK_BEGIN,
  FETCH_LOOKBOOK_FAILURE,
  FETCH_LOOKBOOK_SUCCESS
} from "../actions/lookbook";
import { ILookbook } from "../types/ILookbook";

export interface ILookbookState {
  error: any;
  items: ILookbook[];
  loading: boolean;
}

const initialState: ILookbookState = {
  error: null,
  items: [],
  loading: false
};

export const lookbook = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & {
    payload: {
      lookbook: ILookbook[];
      error: any;
      location: { pathname: string };
    };
  }
): ILookbookState => {
  switch (type) {
    case FETCH_LOOKBOOK_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_LOOKBOOK_SUCCESS:
      return {
        ...state,
        items: payload.lookbook,
        loading: false
      };

    case FETCH_LOOKBOOK_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
