import { AnyAction } from "redux";
import { IBlogPost } from "../types/IBlogPost";
import {
  FETCH_BLOG_BEGIN,
  FETCH_BLOG_FAILURE,
  FETCH_BLOG_POST_BEGIN,
  FETCH_BLOG_POST_FAILURE,
  FETCH_BLOG_POST_SUCCESS,
  FETCH_BLOG_SUCCESS
} from "../actions/blog";
import { IPost } from "../types/IPost";

export interface IBlogState {
  error: any;
  items: IPost[];
  current: IBlogPost | null;
  loading: boolean;
}

const initialState: IBlogState = {
  error: null,
  items: [],
  current: null,
  loading: false
};

export const blog = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & { payload: { error: any; blog: IPost[]; post: IBlogPost } }
): IBlogState => {
  switch (type) {
    case FETCH_BLOG_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_BLOG_SUCCESS:
      return {
        ...state,
        items: payload.blog,
        loading: false
      };

    case FETCH_BLOG_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };
    case FETCH_BLOG_POST_BEGIN:
      return {
        ...state,
        current: null,
        error: null,
        loading: true
      };

    case FETCH_BLOG_POST_SUCCESS:
      return {
        ...state,
        current: payload.post,
        loading: false
      };

    case FETCH_BLOG_POST_FAILURE:
      return {
        ...state,
        error: payload.error,
        current: null,
        loading: false
      };

    default:
      return state;
  }
};
