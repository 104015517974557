import { connect } from "react-redux";
import { fetchCollections } from "../actions/collections";
import { IStore } from "../reducers";
import { DataLoader } from "./DataLoader";

const mapStateToProps = (state: IStore) => ({
  collections: state.collections.items
});

const mapDispatchToProps = {
  fetchCollections
};

const DataLoaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataLoader);

export { DataLoaderContainer };
