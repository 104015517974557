import { AnyAction } from "redux";
import { UPDATE_HOME_COLLECTION } from "../actions/home";
import { ICollection } from "../types/ICollection";

export interface IHomeState {
  currentCollection: ICollection | null;
}

const initialState: IHomeState = {
  currentCollection: null
};

export const home = (
  state = initialState,
  { type, payload }: AnyAction & { payload: { collection: ICollection } }
): IHomeState => {
  switch (type) {
    case UPDATE_HOME_COLLECTION:
      return {
        ...state,
        currentCollection: payload.collection
      };
    default:
      return state;
  }
};
