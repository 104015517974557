import axios from "axios";
import R_pathOr from "ramda/es/pathOr";
import * as React from "react";
import { ie11 } from "../../utils";
import { Colors } from "../../utils/Colors";
import styled, { css } from "styled-components";
import MetaShare from "../MetaShare";
import { PageTitle } from "../shared/PageTitle";

interface IProps {
  updateAppBgColor: (color: string) => void;
}

interface IState {
  title: string;
  content: string;
}

const title = R_pathOr("", ["title", "rendered"]);
const content = R_pathOr("", ["content", "rendered"]);

class Contact extends React.PureComponent<IProps, IState> {
  public state = {
    title: "",
    content: ""
  };

  public componentDidMount() {
    this.props.updateAppBgColor(Colors.WHITE);
    this.load();
  }

  public render() {
    return (
      <>
        <MetaShare title="Lola Valentina - Contact Us" />
        <ContactView dangerouslySetInnerHTML={{ __html: this.state.content }} />
        <PageTitle title={`${this.state.title}`} />
      </>
    );
  }
  private async load() {
    const { data } = await axios({
      url: "/wp-json/wp/v2/pages",
      params: {
        slug: "contact-us"
      }
    });

    const [page] = data;

    this.setState(() => ({
      content: content(page),
      title: title(page)
    }));
  }
}

const ContactView = styled.div`
  font-size: 1.17em;

  > div p {
    user-select: all;
  }

  display: block;
  text-align: center;
  flex: 1 1 100%;
  order: 2;

  p {
    margin: 0.3rem 1rem;
  }

  a {
    display: block;
    margin: 0.3rem 0;
    &:hover {
      font-weight: bold;
    }
  }

  @media (min-width: 425px) {
    padding: 5rem 0 0;
    order: 1;
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
      padding: 0;
      width: 30%;
    }
  }

  ${ie11(css`
    > div {
      width: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  `)};
`;

export { Contact };
