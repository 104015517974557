import axios from "axios";
import R_find from "ramda/es/find";
import R_map from "ramda/es/map";
import { IBlogPost } from "../types/IBlogPost";
import { IPostCategory } from "../types/IPostCategory";
import { IPost } from "../types/IPost";

export const FETCH_BLOG_BEGIN = "FETCH_BLOG_BEGIN";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAILURE = "FETCH_BLOG_FAILURE";

export const FETCH_BLOG_POST_BEGIN = "FETCH_BLOG_POST_BEGIN";
export const FETCH_BLOG_POST_SUCCESS = "FETCH_BLOG_POST_SUCCESS";
export const FETCH_BLOG_POST_FAILURE = "FETCH_BLOG_POST_FAILURE";

export const fetchBlogBegin = () => ({
  type: FETCH_BLOG_BEGIN
});

export const fetchBlogSuccess = (blog: IPost[]) => ({
  payload: { blog },
  type: FETCH_BLOG_SUCCESS
});

export const fetchBlogFailure = (error: any) => ({
  payload: { error },
  type: FETCH_BLOG_FAILURE
});

export const fetchBlogPostBegin = () => ({
  type: FETCH_BLOG_POST_BEGIN
});

export const fetchBlogPostSuccess = (post: IBlogPost) => ({
  payload: { post },
  type: FETCH_BLOG_POST_SUCCESS
});

export const fetchBlogPostFailure = (error: any) => ({
  payload: { error },
  type: FETCH_BLOG_POST_FAILURE
});

export function fetchBlog() {
  return function action(dispatch: any) {
    dispatch(fetchBlogBegin());

    const request = axios({
      headers: [],
      method: "GET",
      url: `/wp-json/wp/v2/posts`,
      params: {
        orderby: "date",
        status: "publish",
        per_page: 100
      }
    });

    return request.then(
      ({ data }: { data: IPost[] }) => {
        dispatch(fetchBlogSuccess(data));
      },
      err => dispatch(fetchBlogFailure(err))
    );
  };
}

export function fetchBlogPost(id: string) {
  return function action(dispatch: any) {
    dispatch(fetchBlogPostBegin());

    const request = axios({
      headers: [],
      method: "GET",
      url: `/wp-json/wp/v2/posts/${id}`
    });

    const requestCategories = axios({
      headers: [],
      method: "GET",
      url: `/wp-json/wp/v2/categories`
    });

    return Promise.all([request, requestCategories]).then(
      ([{ data: post }, { data: categories }]) => {
        post.categories = R_map(
          postCatId =>
            R_find((cat: IPostCategory) => cat.id === postCatId, categories),
          post.categories
        );

        dispatch(fetchBlogPostSuccess(post));
      },
      err => dispatch(fetchBlogPostFailure(err))
    );
  };
}

// Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
