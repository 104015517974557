import { connect } from "react-redux";
import { fetchBlog } from "../../actions/blog";
import { IStore } from "../../reducers";
import { RelatedPosts } from "./RelatedPosts";

const mapStateToProps = (state: IStore, ownProps: any) => ({
  media: state.media.items,
  posts: state.blog.items
});

const mapDispatchToProps = {
  fetchBlog
};

const RelatedPostsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatedPosts);

export { RelatedPostsContainer };
