import { compose, map, range, uniq } from "ramda";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IMedia } from "../../types/IMedia";
import { IProduct } from "../../types/IProduct";
import { VisibilityFilterContainer } from "../shared/VisibilityFilterContainer";
import { ProductGalleryView } from "./ProductGallery";

interface IProps {
  children?: JSX.Element[];
  media: IMedia[];
  products: IProduct[];
  fetchProducts: () => void;
  fetchMedia: (listIds: string[], cache: IMedia[]) => void;
}

export function ProductsLoader({
  children,
  media,
  products,
  fetchProducts,
  fetchMedia
}: IProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (products.length > 0) {
      const uniqueMediaIds = compose<IProduct[], string[], string[]>(
        uniq,
        map((x: IProduct) => x.product_gallery.ID)
      );

      fetchMedia(uniqueMediaIds(products), media);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, fetchMedia]);

  return (
    <View>
      <div>
        <VisibilityFilterContainer />
        <ProductGalleryView>
          {isLoading ? (
            <ul>
              {range(0, 9).map((x, i) => (
                <li key={i} />
              ))}
            </ul>
          ) : (
            <>{children}</>
          )}
        </ProductGalleryView>
      </div>
    </View>
  );
}

export const NextPage = styled.div`
  font-size: 1.1rem;
  margin: 1rem 0 5rem;
  &:hover {
    font-weight: bold;
  }
`;

export const View = styled.div`
  padding: 5rem 0 0;
  width: 100%;
  text-align: center;

  > div > a {
    display: block;
    font-size: 1.1rem;
    margin: 2rem 0;
  }

  @media (min-width: 425px) {
    padding: 10.2rem 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > div {
      width: 58rem;
    }
  }
`;
