import * as React from "react";
import { useEffect } from "react";
import { ICollection } from "../types/ICollection";
import { Loading } from "./shared/Loading";

interface IProps {
  children?: JSX.Element[];
  collections: ICollection[];
  fetchCollections: () => void;
}

export function DataLoader({
  children,
  collections,
  fetchCollections
}: IProps) {
  useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  const isLoading = collections.length === 0;

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
}
