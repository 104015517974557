import { connect } from "react-redux";
import { updateAppBgColor } from "../../actions/app";
import { updateCollection } from "../../actions/home";
import { IStore } from "../../reducers";
import { HomeGallery } from "./HomeGallery";

const mapStateToProps = (state: IStore) => ({
  collections: state.collections.items
});

const mapDispatchToProps = {
  updateCollection,
  updateAppBgColor
};

const HomeGalleryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeGallery);

export { HomeGalleryContainer };
