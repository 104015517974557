import * as React from "react";
import { useEffect } from "react";
import Helmet from "react-helmet";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { IMedia } from "../../types/IMedia";
import { IPortfolio } from "../../types/IPortfolio";
import { Colors } from "../../utils/Colors";
import MetaShare from "../MetaShare";
import { PortfolioSlider } from "./PortfolioSlider";

interface IProps extends RouteComponentProps {
  fetchPortfolio: () => void;
  media: IMedia[];
  portfolio: IPortfolio[];
  updateAppBgColor: (color: string) => void;
}

export function Portfolio({ updateAppBgColor, portfolio, media }: IProps) {
  useEffect(() => {
    updateAppBgColor(Colors.BRICK);
  }, [updateAppBgColor]);

  if (portfolio.length === 0) {
    return <>{withoutItems(<h3>There are no items yet.</h3>)}</>;
  } else {
    return (
      <View>
        <Helmet>
          <body className="header-fixed" />
        </Helmet>
        <MetaShare title="Lola Valentina - Gallery" />
        <PortfolioSlider media={media} items={portfolio} />
      </View>
    );
  }
}

const withoutItems = (children: JSX.Element) => (
  <View>
    <div>
      {children}
      <Link to="/">Return to home</Link>
    </div>
  </View>
);

const View = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
