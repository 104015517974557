import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { fetchBlog } from "../../actions/blog";
import { fetchMedia } from "../../actions/media";
import { IStore } from "../../reducers";
import { Blog } from "./Blog";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  posts: state.blog.items
});

const mapDispatchToProps = {
  fetchBlog,
  fetchMedia,
  updateAppBgColor
};

const BlogContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Blog)
);

export { BlogContainer };
