import { combineReducers } from "redux";
import { app, IAppState } from "./app";
import { blog, IBlogState } from "./blog";
import { collections, ICollectionState } from "./collections";
import { home, IHomeState } from "./home";
import { ILookbookState, lookbook } from "./lookbook";
import { IMediaState, media } from "./media";
import { IMenuState, menu } from "./menu";
import { IPortfolioState, portfolio } from "./portfolio";
import { IProductState, products } from "./products";
import { IProductCategoryState, productCategories } from "./product_categories";
import { ITextilesState, textiles } from "./textiles";

export interface IStore {
  app: IAppState;
  blog: IBlogState;
  collections: ICollectionState;
  home: IHomeState;
  isAppPreview: boolean;
  lookbook: ILookbookState;
  media: IMediaState;
  menu: IMenuState;
  portfolio: IPortfolioState;
  productCategories: IProductCategoryState;
  products: IProductState;
  textiles: ITextilesState;
}

const rootReducer = ({ isAppPreview }: { isAppPreview: boolean }) =>
  combineReducers<IStore>({
    app,
    blog,
    collections,
    home,
    isAppPreview: () => isAppPreview,
    lookbook,
    media,
    menu,
    portfolio,
    productCategories,
    products,
    textiles
  });

export default rootReducer;
