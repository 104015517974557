import * as R from "ramda";
import * as React from "react";
import styled from "styled-components";
import { ICollection } from "../../types/ICollection";
import { randMax } from "../../utils";
import { ImageLoader } from "../shared/ImageLoader";
import { HomeGalleryImageItem } from "./HomeGalleryImageItem";

interface IXY {
  x: number;
  y: number;
}

interface IProps {
  collections: ICollection[];
  updateCollection: (collection: ICollection) => void;
  updateAppBgColor: (color: string) => void;
}

interface IState {
  direction: number;
  currentIdx: number;
  positions: IXY[];
}

class HomeGallery extends React.PureComponent<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    // this happens the first time we get items
    if (props.collections.length !== state.positions.length) {
      // update the collection name and color
      // givin the first item in the collection
      const collection: ICollection = props.collections[0];
      props.updateCollection(collection);
      props.updateAppBgColor(collection.collection_color);
      // get some random positions for each item
      return {
        currentIdx: state.currentIdx,
        positions: props.collections.map(() => ({
          x: randMax(0.7),
          y: randMax(0.3)
        }))
      };
    }

    return null;
  }

  public state = {
    currentIdx: 0,
    direction: 1,
    positions: []
  };

  private list = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    this.updateCurrentIdx(this.props.collections.length - 1, false)(null);
  }

  public componentDidUpdate(_: IProps, prevState: IState) {
    if (this.state.currentIdx !== prevState.currentIdx) {
      // let's update the collection name
      // when the current index changes
      const collection: ICollection = this.props.collections[
        this.state.currentIdx
      ];
      this.props.updateAppBgColor(collection.collection_color);
      this.props.updateCollection(collection);
    }
  }

  public render() {
    const { collections } = this.props;
    const { currentIdx } = this.state;
    const nextIdx = currentIdx + this.state.direction;
    const positions: IXY[] = this.state.positions;
    const pathToFeaturedImage = R.path(["collection_featured_image", "guid"]);

    return (
      <View ref={this.list} onClick={this.updateCurrentIdx(nextIdx, true)}>
        {collections.map((x: ICollection, index: number) => (
          <HomeGalleryImageItem
            onTap={this.updateCurrentIdx(nextIdx, false)}
            key={x.id.toString()}
            visible={index <= currentIdx}
            x={positions[index].x}
            y={positions[index].y}
          >
            <ImageLoader
              height="100%"
              src={pathToFeaturedImage(x)}
              alt={x.title.rendered}
            />
          </HomeGalleryImageItem>
        ))}
      </View>
    );
  }

  private updateCurrentIdx = (currentIdx: number, force: boolean) => (
    event: any
  ) => {
    if (force && event.currentTarget !== event.target) {
      return;
    }
    // updates the collection name based on the top item,
    // cycle: the direction keeps 1 until it reachs the latest item,
    // then it changes to -1 until it reachs the first item
    // the background color changes based on the item

    const { collections } = this.props;

    let direction: number;

    if (currentIdx === collections.length - 1) {
      direction = -1;
    } else if (currentIdx === 0) {
      direction = 1;
    } else {
      direction = this.state.direction;
    }

    this.setState(() => ({
      currentIdx,
      direction
    }));
  };
}

const View = styled.div`
  overflow: hidden;
  flex: 1 0 100%;
  width: 100%;
  text-align: center;
`;

export { HomeGallery };
