import * as React from "react";
import { IMedia } from "../../types/IMedia";
import { IPortfolio } from "../../types/IPortfolio";
import { PortfolioSliderItem } from "./PortfolioSliderItem";
import { ScrollArrow } from "./ScrollArrow";

interface IProps {
  items: IPortfolio[];
  media: IMedia[];
}

class PortfolioSlider extends React.PureComponent<IProps> {
  public render() {
    const { items, media } = this.props;

    return (
      <>
        {items.map((x: IPortfolio, index: number) => (
          <PortfolioSliderItem key={index} item={x} media={media} />
        ))}
        <ScrollArrow />
      </>
    );
  }
}

export { PortfolioSlider };
