export const params = {
  direction: "horizontal",
  loop: false,
  slidesPerView: "auto",
  spaceBetween: 100,
  freeMode: true,
  freeModeSticky: false,
  freeModeMomentum: true,
  preventInteractionOnTransition: true,
  mousewheel: {
    enabled: true,
    releaseOnEdges: true
  },
  autoplay: false,
  watchSlidesVisibility: true,
  preloadImages: true,
  updateOnImagesReady: true
};
