import { filter, map } from "ramda";
import * as React from "react";
import { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled, { css } from "styled-components";
import { IMedia } from "../../types/IMedia";
import { IPost, IPostFeaturedImage } from "../../types/IPost";
import { ie11 } from "../../utils";
import { Colors } from "../../utils/Colors";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import MetaShare from "../MetaShare";
import { Loading } from "../shared/Loading";
import { BlogItem } from "./BlogItem";

interface IProps extends RouteComponentProps {
  fetchBlog: () => void;
  fetchMedia: (ids: string[], cache: IMedia[]) => void;
  media: IMedia[];
  posts: IPost[];
  updateAppBgColor: (color: string) => void;
}

export function Blog({
  fetchBlog,
  fetchMedia,
  media,
  posts,
  updateAppBgColor
}: IProps) {
  useMemo(() => {
    updateAppBgColor(Colors.PALE_PINK);
    fetchBlog();
  }, [updateAppBgColor, fetchBlog]);

  useMemo(() => {
    const postsWithFeaturedImage = filter(
      (x: IPost) => x.featured_image !== false,
      posts
    );

    const ids = map(
      (x: IPost) => (x.featured_image as IPostFeaturedImage).ID,
      postsWithFeaturedImage
    );

    if (ids.length === 0) {
      return;
    }

    fetchMedia(ids, []);
  }, [posts, fetchMedia]);

  if (media.length === 0) {
    return <Loading />;
  }

  const m = (p: IPost) =>
    findMediaByGUID((p.featured_image as IPostFeaturedImage).guid, media);
  const mediaUrl = (p: IPost) => imageUrl(m(p));

  return (
    <View>
      <MetaShare title="Lola Valentina - Blog" />
      <ul>
        {posts.map((x: IPost) => (
          <BlogItem key={x.id} post={x} image={mediaUrl(x)} />
        ))}
      </ul>
    </View>
  );
}

const View = styled.div`
  padding: 1rem;
  flex: 1 0 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }

  @media (min-width: 425px) {
    padding: 10.2rem 0;
    width: 100%;
    max-width: 58rem;
  }

  ${ie11(css`
    ul {
      display: block;
      margin: 0;
      li {
        padding: 0;
        border: 10px solid transparent;
        width: 32%;
        float: left;
      }
    }
  `)};
`;
