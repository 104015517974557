import format from "date-fns/format";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import FacebookShareButton from "react-share/es/FacebookShareButton";
import PinterestShareButton from "react-share/es/PinterestShareButton";
import TwitterShareButton from "react-share/es/TwitterShareButton";
import { IBlogPost } from "../../types/IBlogPost";
import { IMedia } from "../../types/IMedia";
import { ie11 } from "../../utils";
import { Colors } from "../../utils/Colors";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import UrlUtils from "../../utils/UrlUtils";
import styled, { css } from "styled-components";
import MetaShare from "../MetaShare";
import { ImageLoader } from "../shared/ImageLoader";
import { Loading } from "../shared/Loading";
import { RelatedPostsContainer } from "./RelatedPostsContainer";

interface IParams {
  post_id: string | undefined;
}

interface IProps extends RouteComponentProps<IParams> {
  current: IBlogPost | null;
  media: IMedia[];
  fetchBlogPost: (id: string) => void;
  updateAppBgColor: (color: string) => void;
}

class BlogPostItem extends React.PureComponent<IProps> {
  public componentDidMount() {
    this.props.updateAppBgColor(Colors.PALE_PINK);
    const postId: string | undefined = this.props.match.params.post_id;
    if (postId) {
      this.props.fetchBlogPost(postId);
    }
  }

  public componentDidUpdate() {
    const postId: string | undefined = this.props.match.params.post_id;
    if (
      postId &&
      this.props.current &&
      postId !== this.props.current.id.toString()
    ) {
      this.props.fetchBlogPost(postId);
    }
  }

  public render() {
    if (!this.props.current || this.props.media.length === 0) {
      return <Loading />;
    }

    const { current, media } = this.props;
    const { date, title, content, categories, featured_image } = current;

    const image = imageUrl(findMediaByGUID(featured_image.guid, media));
    const shareUrl = UrlUtils.location(document);
    const pinterestUrl = `${UrlUtils.origin(document)}${image}`;

    return (
      <Article>
        <MetaShare
          title={`Lola Valentina - ${title.rendered}`}
          image={pinterestUrl}
        />
        <Header>
          <div>
            <ImageLoader src={image} width="100%" />
          </div>
          <PostHeader>
            <h2>{title.rendered}</h2>
            <PostCategories>
              {categories.map(category => (
                <li key={category.id}>{category.name}</li>
              ))}
            </PostCategories>
            <time>{format(date, "MMMM DD, YYYY")}</time>
            <h3>Share this +</h3>
            <PostCategories>
              <li>
                <FacebookShareButton quote={title.rendered} url={shareUrl}>
                  FACEBOOK
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton url={shareUrl} title={title.rendered}>
                  TWITTER
                </TwitterShareButton>
              </li>
              <li>
                <PinterestShareButton url={shareUrl} media={pinterestUrl}>
                  PINTEREST
                </PinterestShareButton>
              </li>
            </PostCategories>
          </PostHeader>
        </Header>

        <Content dangerouslySetInnerHTML={{ __html: content.rendered }} />
        <RelatedPostsContainer post={this.props.current} />
      </Article>
    );
  }
}

const Article = styled.article`
  padding: 1rem 2rem;
  max-width: 58rem;
  @media (min-width: 425px) {
    padding: 10.2rem 2rem;
  }
`;

const PostHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding-bottom: 3rem;

  h2 {
    font-size: 1.7rem;
    font-weight: 500;
    margin: 1rem 0;
  }

  h3 {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  time {
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
`;

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  > div > img {
    float: right;
  }

  @media (min-width: 425px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem;
  }

  > div,
  ${PostHeader} {
    flex: 1 0 100%;

    @media (min-width: 425px) {
      flex: 1 0 50%;
    }
  }

  ${ie11(css`
    overflow: hidden;
    > div {
      width: 50%;
      float: left;
    }
  `)};
`;

const PostCategories = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0 0.4rem 0 0;
    text-transform: uppercase;
    font-size: 0.8rem;

    > div:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

const Content = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0 5rem;

  @media (min-width: 425px) {
    margin: 5rem 0;
    grid-template-columns: 1fr 1fr;
  }
`;

export { BlogPostItem };
