import * as React from "react";
import styled from "styled-components";
import download from "./download.svg";

export function Download() {
  const DownloadView = styled.img`
    width: 3vw;
    height: min-intrinsic;
  `;

  return <DownloadView src={download} />;
}
