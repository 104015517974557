import { createGlobalStyle } from "styled-components";
import medium from "./fonts/nittigrotesk-medium-v20.woff";
import normal from "./fonts/nittigrotesk-normal-v20.woff";

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'NittiGrotesk-Medium';
    src: url(${medium}) format("woff");
    font-style: normal;
  }

  @font-face {
    font-family: 'NittiGrotesk-Normal';
    src: url(${normal}) format("woff");
    font-style: normal;
  }
`;
