import axios from "axios";
import { ICollection } from "../types/ICollection";

export const FETCH_COLLECTIONS_BEGIN = "FETCH_COLLECTIONS_BEGIN";
export const FETCH_COLLECTIONS_SUCCESS = "FETCH_COLLECTIONS_SUCCESS";
export const FETCH_COLLECTIONS_FAILURE = "FETCH_COLLECTIONS_FAILURE";

export const fetchCollectionsBegin = () => ({
  type: FETCH_COLLECTIONS_BEGIN
});

export const fetchCollectionsSuccess = (collections: ICollection[]) => ({
  payload: { collections },
  type: FETCH_COLLECTIONS_SUCCESS
});

export const fetchCollectionsFailure = (error: any) => ({
  payload: { error },
  type: FETCH_COLLECTIONS_FAILURE
});

export function fetchCollections() {
  return function action(dispatch: any) {
    dispatch(fetchCollectionsBegin());

    const request = axios({
      headers: [],
      method: "GET",
      url: `/wp-json/wp/v2/collection`,
      params: {
        status: "publish",
        per_page: 100
      }
    });

    return request.then(
      ({ data }: { data: ICollection[] }) => {
        dispatch(fetchCollectionsSuccess(data));
      },
      err => dispatch(fetchCollectionsFailure(err))
    );
  };
}

// Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
