import { AnyAction } from "redux";
import {
  FETCH_COLLECTIONS_BEGIN,
  FETCH_COLLECTIONS_FAILURE,
  FETCH_COLLECTIONS_SUCCESS
} from "../actions/collections";
import { ICollection } from "../types/ICollection";

export interface ICollectionState {
  error: any;
  items: ICollection[];
  loading: boolean;
}

const initialState: ICollectionState = {
  error: null,
  items: [],
  loading: false
};

export const collections = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & { payload: { collections: ICollection[]; error: any } }
): ICollectionState => {
  switch (type) {
    case FETCH_COLLECTIONS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_COLLECTIONS_SUCCESS:
      return {
        ...state,
        items: payload.collections,
        loading: false
      };

    case FETCH_COLLECTIONS_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
