import { AnyAction } from "redux";
import {
  FETCH_PORTFOLIO_BEGIN,
  FETCH_PORTFOLIO_FAILURE,
  FETCH_PORTFOLIO_SUCCESS
} from "../actions/portfolio";
import { IPortfolio } from "../types/IPortfolio";

export interface IPortfolioState {
  error: any;
  items: IPortfolio[];
  loading: boolean;
}

const initialState: IPortfolioState = {
  error: null,
  items: [],
  loading: false
};

export const portfolio = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & { payload: { items: IPortfolio[]; error: any } }
): IPortfolioState => {
  switch (type) {
    case FETCH_PORTFOLIO_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_PORTFOLIO_SUCCESS:
      return {
        ...state,
        items: payload.items,
        loading: false
      };

    case FETCH_PORTFOLIO_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
