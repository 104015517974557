import axios from "axios";
import { IPortfolio } from "../types/IPortfolio";

export const FETCH_PORTFOLIO_BEGIN = "FETCH_PORTFOLIO_BEGIN";
export const FETCH_PORTFOLIO_SUCCESS = "FETCH_PORTFOLIO_SUCCESS";
export const FETCH_PORTFOLIO_FAILURE = "FETCH_PORTFOLIO_FAILURE";

export const fetchPortfolioBegin = () => ({
  type: FETCH_PORTFOLIO_BEGIN
});

export const fetchPortfolioSuccess = (items: IPortfolio[]) => ({
  payload: { items },
  type: FETCH_PORTFOLIO_SUCCESS
});

export const fetchPortfolioFailure = (error: any) => ({
  payload: { error },
  type: FETCH_PORTFOLIO_FAILURE
});

export function fetchPortfolio() {
  return function action(dispatch: any) {
    dispatch(fetchPortfolioBegin());

    const request = axios({
      headers: [],
      method: "GET",
      url: `/wp-json/wp/v2/portfolio`,
      params: {
        orderby: "date",
        status: "publish",
        per_page: 100
      }
    });

    return request.then(
      ({ data }: { data: IPortfolio[] }) => {
        dispatch(fetchPortfolioSuccess(data));
      },
      err => dispatch(fetchPortfolioFailure(err))
    );
  };
}

// Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
