import { AnyAction } from "redux";
import {
  FETCH_TEXTILES_BEGIN,
  FETCH_TEXTILES_FAILURE,
  FETCH_TEXTILES_SUCCESS
} from "../actions/textiles";
import { ITextile } from "../types/ITextile";

export interface ITextilesState {
  error: any;
  items: ITextile[];
  loading: boolean;
}

const initialState: ITextilesState = {
  error: null,
  items: [],
  loading: false
};

export const textiles = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & {
    payload: {
      textiles: ITextile[];
      error: any;
      location: { pathname: string };
    };
  }
): ITextilesState => {
  switch (type) {
    case FETCH_TEXTILES_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_TEXTILES_SUCCESS:
      return {
        ...state,
        items: payload.textiles,
        loading: false
      };

    case FETCH_TEXTILES_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
