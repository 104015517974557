import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchMedia } from "../../actions/media";
import { fetchPortfolio } from "../../actions/portfolio";
import { IStore } from "../../reducers";
import { PortfolioLoader } from "./PortfolioLoader";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  portfolio: state.portfolio.items
});

const mapDispatchToProps = {
  fetchPortfolio,
  fetchMedia
};

const PortfolioLoaderContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PortfolioLoader) as any
);

export { PortfolioLoaderContainer };
