import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { IStore } from "../../reducers";
import { categoryNamesGroupedByCollection } from "../../selectors/categories";
import { CollectionsList } from "./CollectionsList";

export const CollectionsListContainer = withRouter(
  connect(
    (state: IStore) => ({
      allCategoryByCollection: categoryNamesGroupedByCollection(state),
      collections: state.collections.items,
      media: state.media.items
    }),
    {
      updateAppBgColor
    }
  )(CollectionsList)
);
