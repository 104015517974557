import axios from "axios";
import { IProductCategory } from "../types/IProductCategory";

export const FETCH_PRODUCT_CATEGORIES_BEGIN = "FETCH_PRODUCT_CATEGORIES_BEGIN";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE =
  "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const fetchProductCategoriesBegin = () => ({
  type: FETCH_PRODUCT_CATEGORIES_BEGIN
});

export const fetchProductCategoriesSuccess = (items: IProductCategory[]) => ({
  payload: { items },
  type: FETCH_PRODUCT_CATEGORIES_SUCCESS
});

export const fetchProductCategoriesFailure = (error: any) => ({
  payload: { error },
  type: FETCH_PRODUCT_CATEGORIES_FAILURE
});

export function fetchProductCategories() {
  return function action(dispatch: any) {
    dispatch(fetchProductCategoriesBegin());

    const request = axios({
      headers: [],
      method: "GET",
      url: `/wp-json/wp/v2/product_category`,
      params: {
        status: "publish",
        per_page: 100
      }
    });

    return request.then(
      ({ data }: { data: IProductCategory[] }) => {
        dispatch(fetchProductCategoriesSuccess(data));
      },
      err => dispatch(fetchProductCategoriesFailure(err))
    );
  };
}

// Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
