import interact from "interactjs";
import * as React from "react";
import styled from "styled-components";
import { InteractEvent, Target } from "@interactjs/types/types";

interface IProps {
  visible: boolean;
  x: number;
  y: number;
  children?: JSX.Element;
  onTap: (event: any) => void;
}

function move(target: HTMLDivElement, x: number, y: number) {
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px)";

  target.setAttribute("data-x", x.toString());
  target.setAttribute("data-y", y.toString());
}

function calc(
  window: Window,
  el: HTMLUListElement,
  prop: keyof CSSStyleDeclaration
) {
  return parseInt(String(window.getComputedStyle(el)[prop]), 10);
}

class HomeGalleryImageItem extends React.PureComponent<IProps> {
  private el = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    const el = this.el.current as HTMLDivElement;
    const parent = el.parentElement as HTMLUListElement;
    const pWidth = calc(window, parent, "width");
    const pHeight = calc(window, parent, "height");

    move(el, this.props.x * pWidth, this.props.y * pHeight);

    interact(this.el.current as Target)
      .draggable({
        inertia: true,
        onmove: this.dragMoveListener
      })
      .on("tap", () => {
        this.props.onTap({});
      });
  }

  public dragMoveListener = (event: InteractEvent) => {
    const target = event.target as HTMLDivElement;
    const x = parseFloat(target.getAttribute("data-x") || "0") + event.dx;
    const y = parseFloat(target.getAttribute("data-y") || "0") + event.dy;

    move(target, x, y);
  };

  public render() {
    return (
      <Wrapper visible={this.props.visible} ref={this.el}>
        {this.props.children}
      </Wrapper>
    );
  }
}

export const Wrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  display: ${({ visible }) => (visible ? "block" : "none")};
  max-width: 50vw;
  max-height: 80vh;
  transform-origin: center;

  > img {
    width: 100%;
  }

  @media (min-width: 425px) {
    max-width: 25vw;
    max-height: 50vh;
  }
`;

export { HomeGalleryImageItem };
