import * as React from "react";
import styled, { css } from "styled-components";
import { ICollection } from "../../types/ICollection";
import { IMedia } from "../../types/IMedia";
import { IProduct } from "../../types/IProduct";
import { ie11 } from "../../utils";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import { ProductGalleryItem } from "./ProductGalleryItem";

interface IProps {
  collections: ICollection[];
  products: IProduct[];
  media: IMedia[];
}

export function ProductGallery({ collections, products, media }: IProps) {
  return (
    <ProductGalleryView>
      <ul>
        {products.map((prod: IProduct, index: number) => {
          const maybeCollections: ICollection[] = collections.filter(
            c => c.id === prod.product_collection
          );

          const [collection] = maybeCollections;

          if (!collection) {
            return null;
          }

          const m = findMediaByGUID(prod.product_gallery.ID, media);
          const mediaUrl = imageUrl(m);

          return (
            <ProductGalleryItem
              key={index}
              collectionSlug={collection.slug}
              collectionTitle={collection.title.rendered}
              product={prod}
              mediaUrl={mediaUrl}
            />
          );
        })}
      </ul>
    </ProductGalleryView>
  );
}

export const ProductGalleryView = styled.div`
  width: 100%;

  > ul {
    display: flex;
    flex-direction: column;

    > li {
      padding-top: 146%;
      transition: background-color 1s 200ms;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  @media (min-width: 425px) {
    max-width: 58rem;

    > ul {
      width: 100%;
      margin: 4.4rem auto;
      display: grid;
      grid-template-columns: 32% 32% 32%;
      grid-gap: 1.2rem 2%;
    }
  }

  ${ie11(css`
    > ul {
      > li {
        border: 10px solid transparent;
        width: 32%;
        float: left;
      }
    }
  `)};
`;
