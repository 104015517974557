import axios from "axios";
import { IProduct } from "../types/IProduct";

export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = (products: IProduct[]) => ({
  payload: { products },
  type: FETCH_PRODUCTS_SUCCESS
});

export const fetchProductsFailure = (error: any) => ({
  payload: { error },
  type: FETCH_PRODUCTS_FAILURE
});

export function fetchProducts() {
  return function action(dispatch: any) {
    dispatch(fetchProductsBegin());

    const request = () =>
      axios({
        headers: [],
        method: "GET",
        url: `/wp-json/lola/v1/product`,
        params: {
          _fields:
            "id,title,product_categories,product_collection,product_gallery,menu_order",
          orderby: "menu_order",
          order: "asc"
        }
      });

    request().then(
      ({ data }: { data: IProduct[] }) => {
        dispatch(fetchProductsSuccess(data));
      },
      err => dispatch(fetchProductsFailure(err))
    );
  };
}

// Handle HTTP errors since fetch won't.
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }
