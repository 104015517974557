import applySpec from "ramda/src/applySpec";
import compose from "ramda/src/compose";
import forEachObjIndexed from "ramda/src/forEachObjIndexed";
import groupBy from "ramda/src/groupBy";
import head from "ramda/src/head";
import isEmpty from "ramda/src/isEmpty";
import map from "ramda/src/map";
import path from "ramda/src/path";
import prop from "ramda/src/prop";
import uniq from "ramda/src/uniq";
import { IProduct } from "../types/IProduct";
import { IStore } from "../reducers/index";

interface ICleanedProductCollection {
  ID: string;
}

interface IEscencialProduct {
  id: string;
  title: string;
  product_categories: any[];
  product_collection: ICleanedProductCollection;
}

export interface ICategoryNamesByCollection {
  [key: string]: string[];
}

const groupByCollection = groupBy(
  compose(
    prop("ID"),
    prop("product_collection")
  )
);

const mapToEsencial = applySpec<IEscencialProduct>({
  id: prop("id"),
  title: path(["title", "rendered"]),
  product_categories: prop("product_categories"),
  product_collection: compose(
    head, // "product_collection" is an array, get the first one
    prop("product_collection")
  )
});

const toCategoryName = compose<IEscencialProduct, any, any, string>(
  prop("name"),
  head,
  prop("product_categories")
);

const groupedByCollection = compose<IProduct[], IEscencialProduct[], any>(
  // group the products by collection
  // this will allow us to get the categories of those
  // products
  groupByCollection,
  // get a list of products but each of them
  // with only esential information
  map<IProduct, IEscencialProduct>(mapToEsencial)
);

export function categoryNamesGroupedByCollection(
  store: IStore
): ICategoryNamesByCollection {
  if (
    isEmpty(store.products.items) ||
    isEmpty(store.products.categories) ||
    isEmpty(store.collections.items)
  ) {
    return {};
  } else {
    // this object will store an object that use the
    // category.ID as key, and will store the categories
    // inside each category
    // {
    //    x: ['category-1', 'category-2', ...],
    //    y: ['category-5', ...]
    // }
    const grouped: ICategoryNamesByCollection = {};
    const collections = groupedByCollection(store.products.items);

    // iterate over each collection and for each product
    // extract the category name. since we may have
    // product with the same category, let's a unique list
    forEachObjIndexed((value: IEscencialProduct[], key: string) => {
      const catsInCollection = map(toCategoryName, value);
      grouped[key] = uniq(catsInCollection);
    }, collections);

    return grouped;
  }
}
