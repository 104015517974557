import { connect } from "react-redux";
import { withRouter } from "react-router";
import { IStore } from "../../reducers";
import { VisibilityFilter } from "./VisibilityFilter";

const mapStateToProps = (state: IStore) => ({
  categories: state.productCategories.items,
  collections: state.collections.items
});

const VisibilityFilterContainer = withRouter(
  connect(mapStateToProps)(VisibilityFilter)
);

export { VisibilityFilterContainer };
