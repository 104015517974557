import styled from "styled-components";

export const CollectionGalleryImageItem = styled.div`
  margin: 2em;

  &.swiper-slide {
    cursor: pointer;
    margin: 0;
    width: 455px;
  }
`;
