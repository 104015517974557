import { AnyAction } from "redux";
import {
  FETCH_PRODUCT_CATEGORIES_BEGIN,
  FETCH_PRODUCT_CATEGORIES_FAILURE,
  FETCH_PRODUCT_CATEGORIES_SUCCESS
} from "../actions/product_categories";
import { IProductCategory } from "../types/IProductCategory";

export interface IProductCategoryState {
  error: any;
  items: IProductCategory[];
  loading: boolean;
}

const initialState: IProductCategoryState = {
  error: null,
  items: [],
  loading: false
};

export const productCategories = (
  state = initialState,
  {
    type,
    payload
  }: AnyAction & { payload: { items: IProductCategory[]; error: any } }
): IProductCategoryState => {
  switch (type) {
    case FETCH_PRODUCT_CATEGORIES_BEGIN:
      return {
        ...state,
        error: null,
        loading: true
      };

    case FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        items: payload.items,
        loading: false
      };

    case FETCH_PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        error: payload.error,
        items: [],
        loading: false
      };

    default:
      return state;
  }
};
