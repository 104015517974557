import { filter, find } from "ramda";
import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ICollection } from "../../types/ICollection";
import { IMedia } from "../../types/IMedia";
import { IProduct } from "../../types/IProduct";
import { IProductCategory } from "../../types/IProductCategory";
import { Colors } from "../../utils/Colors";
import { PageTitle } from "../shared/PageTitle";
import { ProductGallery } from "./ProductGallery";

interface IParams {
  category_slug: string;
}

interface IProps extends RouteComponentProps<IParams> {
  categories: IProductCategory[];
  collections: ICollection[];
  products: IProduct[];
  media: IMedia[];
  updateAppBgColor: (color: string) => void;
}

export function Products({
  updateAppBgColor,
  categories,
  collections,
  products,
  media,
  match: {
    params: { category_slug: slug }
  }
}: IProps) {
  const [filtered, setFiltered] = useState<IProduct[]>([]);

  useEffect(() => {
    updateAppBgColor(Colors.WHITE);

    const category: IProductCategory | undefined = find(
      c => c.slug === slug,
      categories
    );

    // if we are in a sub-page we filter by category
    if (category) {
      const pred = (p: IProduct) =>
        (p.product_categories as unknown) === category.id;
      setFiltered(filter(pred, products));
    } else {
      setFiltered(products);
    }
  }, [slug, categories, products, updateAppBgColor]);

  return (
    <>
      <ProductGallery
        products={filtered}
        collections={collections}
        media={media}
      />
      <PageTitle title="Products" />
    </>
  );
}
