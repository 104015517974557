import difference from "ramda/es/difference";
import filter from "ramda/es/filter";
import map from "ramda/es/map";
import take from "ramda/es/take";
import * as React from "react";
import { IBlogPost } from "../../types/IBlogPost";
import { IMedia } from "../../types/IMedia";
import { IPost, IPostFeaturedImage } from "../../types/IPost";
import { IPostCategory } from "../../types/IPostCategory";
import { ie11 } from "../../utils";
import { findMediaByGUID, imageUrl } from "../../utils/Media";
import styled, { css } from "styled-components";
import { BlogItem } from "./BlogItem";

const hasAnyCategory = (categories: IPostCategory[]) => (post: IPost) => {
  const ids1 = map(x => x.id, categories);
  const ids2 = map(x => x, post.categories);
  const diff = difference(ids1, ids2);
  return diff.length > 0;
};

interface IProps {
  post: IBlogPost;
  fetchBlog: () => void;
  posts: IPost[];
  media: IMedia[];
}

class RelatedPosts extends React.PureComponent<IProps> {
  public componentDidMount() {
    this.props.fetchBlog();
  }

  public render() {
    const { media, post, posts } = this.props;
    const related = filter(hasAnyCategory(post.categories), posts);
    return (
      <Related>
        <h3>Related</h3>
        <ul>
          {map(
            (x: IPost) => (
              <BlogItem
                key={x.id}
                post={x}
                image={imageUrl(
                  findMediaByGUID(
                    (x.featured_image as IPostFeaturedImage).guid,
                    media
                  )
                )}
              />
            ),
            take<IPost>(3, related)
          )}
        </ul>
      </Related>
    );
  }
}

const Related = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 500;
    text-align: center;
    margin: 1rem 0;
  }

  > ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
  }

  @media (min-width: 425px) {
    ul {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1.2rem 2%;
    }
  }

  ${ie11(css`
    > ul > li {
      width: 32%;
      border: 10px solid transparent;
      float: left;
    }
  `)};
`;

export { RelatedPosts };
