import * as React from "react";
import { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ICollection } from "../../types/ICollection";
import { ILookbook } from "../../types/ILookbook";
import { IMedia } from "../../types/IMedia";
import { Colors } from "../../utils/Colors";
import { LookbookGallery } from "./LookbookGallery";
import { PageTitle } from "../shared/PageTitle";

interface IParams {
  category_slug: string;
}

interface IProps extends RouteComponentProps<IParams> {
  collections: ICollection[];
  lookbook: ILookbook[];
  media: IMedia[];
  updateAppBgColor: (color: string) => void;
}

export function Lookbook({
  updateAppBgColor,
  collections,
  lookbook,
  media
}: IProps) {
  useMemo(() => {
    updateAppBgColor(Colors.WHITE);
  }, [updateAppBgColor]);

  return (
    <>
      <LookbookGallery
        collections={collections}
        lookbook={lookbook}
        media={media}
      />
      <PageTitle title="Lookbook" />
    </>
  );
}
