import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateAppBgColor } from "../../actions/app";
import { IStore } from "../../reducers";
import { Textiles } from "./Textiles";

const mapStateToProps = (state: IStore) => ({
  media: state.media.items,
  textiles: state.textiles.items
});

const mapDispatchToProps = {
  updateAppBgColor
};

const TextilesContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Textiles)
);

export { TextilesContainer };
