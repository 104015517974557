import * as React from "react";
import { ICategoryNamesByCollection } from "../../selectors/categories";
import { ICollection } from "../../types/ICollection";
import { IMedia } from "../../types/IMedia";
import styled from "styled-components";
import { CollectionsListGalleryItem } from "./CollectionsListGalleryItem";

interface IProps {
  allCategoryByCollection: ICategoryNamesByCollection;
  collections: ICollection[];
  media: IMedia[];
}

export function CollectionsListGallery({
  allCategoryByCollection: byCollection,
  collections,
  media
}: IProps) {
  return (
    <View>
      <ul>
        {collections.map((collection, index) => {
          const inCollection = byCollection[collection.id] || [];
          return (
            <CollectionsListGalleryItem
              key={index}
              categoriesInCollection={inCollection}
              collection={collection}
              media={media}
            />
          );
        })}
      </ul>
    </View>
  );
}

const View = styled.div`
  width: 100%;

  > ul {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 425px) {
    max-width: 58rem;

    > ul {
      display: grid;
      margin: 4.4rem auto;
      grid-template-columns: 32% 32% 32%;
      grid-gap: 1.2rem 2%;
    }
  }
`;
