import * as React from "react";
import { useRef, useState } from "react";
import styled from "styled-components";

export function Container({
  src,
  className = ""
}: {
  src: string;
  className?: string;
}) {
  const image = useRef(null);
  const [loaded, setLoaded] = useState(false);

  function onLoad() {
    setLoaded(true);
  }

  const img = (
    //@ts-ignore
    <img loading="lazy" alt="" onLoad={onLoad} ref={image} src={src} />
  );
  return (
    <div className={[className, !loaded ? "loading" : ""].join(" ")}>{img}</div>
  );
}

export const BackgroundImage = styled(Container)`
  position: relative;
  width: 100%;
  padding-top: 146%;
  display: flex;
  align-items: center;

  @keyframes grad {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  background: none;

  &.loading {
    background: linear-gradient(-45deg, #efefef, #fff);
    background-size: 400% 400%;
    animation: grad 15s ease infinite;

    img {
      opacity: 0;
    }
  }

  img {
    transition: opacity 400ms ease-in;
    opacity: 1;
    width: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;
