import * as React from "react";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const noop = () => {};

export function ImageLoader({
  src,
  alt,
  width,
  height
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  const [loaded, setLoaded] = useState(false);
  const img = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const image = img.current;

    if (image) {
      image.onload = () => setLoaded(true);
      image.setAttribute("src", src ?? "");
    }

    return () => {
      image && (image.onload = noop);
    };
  }, [img, src]);

  return (
    <StyledImage
      ref={img}
      src={src}
      alt={alt}
      width={width}
      height={height}
      loaded={loaded}
    />
  );
}

const StyledImage = styled.img<{ loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 300ms;
`;
