import compose from "ramda/es/compose";
import contains from "ramda/es/contains";
import flip from "ramda/es/flip";
import isEmpty from "ramda/es/isEmpty";
import prop from "ramda/es/prop";
import reject from "ramda/es/reject";
import toUpper from "ramda/es/toUpper";
import { IStore } from "../reducers/index";
import { ICollectionFeaturedImage, ICustomizer } from "../types/ICollection";
import { ICustomizerData, ICustomizerMenuData } from "../types/ICustomizerData";
import { IProductCategory } from "../types/IProductCategory";
import { fixUrl } from "../utils/Media";

const LIST_ENABLED = ["tablecloth", "placemat", "napkins"];

const isSlugIn = (list: string[]) => {
  return compose<IProductCategory, string, boolean>(
    flip(contains)(list),
    prop("slug")
  );
};

export function customizer(store: IStore): ICustomizerData[] {
  const allCollections = store.collections.items;
  const allCategories = store.productCategories.items;
  const isDataLoaded = !isEmpty(allCategories) && !isEmpty(allCollections);

  // let's wait until all data is loaded (products, categories and collections)
  if (isDataLoaded) {
    // get only those categories that we enabled in LIST_ENABLED
    const categories = allCategories.filter(isSlugIn(LIST_ENABLED));

    const staticTableData = [{
      id: "default",
      label: "DEFAULT",
      textures: [
        {
          id: "black",
          title: "BLACK TABLE",
          thumbnail: "/wp-content/themes/lola-valentina/assets/img/customizer/patterns/black_table.jpg",
          texture:
            "/wp-content/themes/lola-valentina/assets/img/customizer/patterns/black_table.jpg",
          url: ""
        },
        {
          id: "white",
          title: "WHITE TABLE",
          thumbnail: "/wp-content/themes/lola-valentina/assets/img/customizer/patterns/white_table.jpg",
          texture:
            "/wp-content/themes/lola-valentina/assets/img/customizer/patterns/white_table.jpg",
          url: ""
        },
        {
          id: "wood",
          title: "WOOD TABLE",
          thumbnail: "/wp-content/themes/lola-valentina/assets/img/customizer/patterns/wood_table.jpg",
          texture:
            "/wp-content/themes/lola-valentina/assets/img/customizer/patterns/wood_table.jpg",
          url: ""
        }
      ]
    }];

    // get the categories with the projects
    // the projects included are only those with textures!

    const categoriesOnlyWithCustomizer = reject(
      x => x.customizer === false,
      allCollections
    );

    const evolved = categories.map((pc: IProductCategory) => ({
      id: pc.slug,
      label: toUpper(pc.name),
      selectedProject: "None",
      projects: categoriesOnlyWithCustomizer.map(c => ({
        id: c.slug,
        label: toUpper(c.title.rendered),
        textures: (c.customizer as ICustomizer[]).map(i => ({
          title: i.post_title ?? "",
          thumbnail: fixUrl(i.thumb?.guid ?? ""),
          texture: fixUrl((i[pc.slug] as ICollectionFeaturedImage).guid ?? ""),
          url: ""
        }))
      }))
    }));

    evolved.map(item => {
      if (item.id === "tablecloth") {
        item.projects = [...staticTableData, ...item.projects];
      }
      return item;
    });

    const out = [{ categories: evolved as ICustomizerMenuData[] }];

    console.log(out);

    return out;
  } else {
    return [];
  }
}
