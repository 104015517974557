import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { customizer } from "../../selectors/customizer";
import { updateAppBgColor } from "../../actions/app";
import { IStore } from "../../reducers";

// @ts-ignore
const lazy = React.lazy;
// @ts-ignore
const Suspense = React.Suspense;
const Customizer = lazy(() => import("./Customizer"));

const mapStateToProps = (state: IStore, ownProps: any) => ({
  items: customizer(state)
});

const mapDispatchToProps = {
  updateAppBgColor
};

const CustomizerContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Suspense fallback={<div>Loading...</div>}>
      <Customizer {...props} />
    </Suspense>
  ))
);

export { CustomizerContainer };
